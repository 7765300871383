

























































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import RemoveIntegratorTableByCompanyDialog from '@/views/integratorConfig/components/dialogs/RemoveIntegratorTableByCompanyDialog.vue';
import CompanyRepository from '@/repositories/CompanyRepository';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import TableConfigurations from '@/domain/models/integrator/TableConfigurations';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import Company from '@/domain/models/Company';
import IntegratorTableNameEnum from '../enums/IntegratorTableNameEnum';
import IntegratorTablesWithFieldsConfigRequest from '../interfaces/requests/IntegratorTablesWithFieldsConfigRequest';
import IIntegratorTablesDataToUpdate from '../interfaces/stores/IIntegratorTablesDataToUpdate';
import IRemoveIntegratorIndexes from '../interfaces/IRemoveIntegratorIndexes';

export default defineComponent({
  components: {
    RemoveIntegratorTableByCompanyDialog,
  },
	name: 'IntegratorTablesConfigTab',
	data() {
		const inputRules: InputRulesHelper = new InputRulesHelper();
		const companyRepository: CompanyRepository = new CompanyRepository();
		const integratorRepository: IntegratorRepository = new IntegratorRepository();

    const removeIntegratorIndexes: IRemoveIntegratorIndexes = {
      tablesFieldIndex: NaN,
      tablesIndex: NaN,
    };

		const tablesConfigList: TableConfigurations[] = [];
		const fieldTypeOptions: ISelectOptions<string>[] = [
			{ value: 'string', text: 'Texto' },
			{ value: 'date', text: 'Data' },
		];

		const loading: boolean = false;
    const removeIntegratorDialogOpen: boolean = false;

		return {
      inputRules,
      integratorRepository,
			companyRepository,
      removeIntegratorIndexes,
      tablesConfigList,
			fieldTypeOptions,
			loading,
      removeIntegratorDialogOpen,
		};
	},
  props: {
    companies: {
      required: true,
      type: Array as () => Company[],
    },
  },
	computed: {
    ...mapState({
      companyGroupId: (state: any) => Number(state.authentication.user.company_group_id) as number,
      selectedDataToUpdate: (state: any): IIntegratorTablesDataToUpdate => state
      .integrator_config.selectedDataToUpdate,
    }),
  },
	created() {
    this.getIntegratorTablesData();
  },
	methods: {
    async getIntegratorTablesData(): Promise<void> {
      try {
        this.loading = true;

        const tables = await this.integratorRepository
          .getTableAndFieldsConfiguration(this.companyGroupId);

        this.tablesConfigList = tables;
        this.$store.commit('integrator_config/setTableFilesData', tables);
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar o arquivo de tabelas do integrador.');
      } finally {
        this.loading = false;
      }
    },
    async saveTableOnFile(tableData: IntegratorTablesWithFieldsConfigRequest): Promise<void> {
      try {
        this.$dialog.startLoading();

        await this.integratorRepository.setTableConfiguration(this.companyGroupId, tableData);
      } catch (error) {
        this.$notification.error('Houve um problema ao enviar os dados do arquivo de tabela.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    getToggleIcon(table: TableConfigurations): string {
      return table.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    getTranslatedTableName(tableName: string): string {
      switch (tableName) {
        case IntegratorTableNameEnum.CUSTOMER: return 'Cliente';
        case IntegratorTableNameEnum.CUSTOMER_NCC: return 'Cliente NCC';
        case IntegratorTableNameEnum.SUPPLIER: return 'Fornecedor';
        case IntegratorTableNameEnum.BANK_BALANCE: return 'Saldo Bancário';
        case IntegratorTableNameEnum.ACCOUNT_PAYABLE: return 'Conta a Pagar';
        case IntegratorTableNameEnum.ACCOUNT_RECEIVABLE: return 'Conta a Receber';
        case IntegratorTableNameEnum.ACCOUNT_RECEIVABLE_NCC: return 'Conta a Receber NCC';
        case IntegratorTableNameEnum.FINANCIAL_NATURE: return 'Natureza Financeira';
        case IntegratorTableNameEnum.BANK: return 'Banco';
        case IntegratorTableNameEnum.COST_CENTER: return 'Centro de Custo';
        case IntegratorTableNameEnum.VALUE_CLASS: return 'Valor de Classe';
        case IntegratorTableNameEnum.CURRENCY: return 'Moeda';
        case IntegratorTableNameEnum.ACCOUNTING_ITEM: return 'Item Contábil';
        case IntegratorTableNameEnum.BANK_TRANSACTION: return 'Transação Bancária';
        default: return '';
      }
    },
    getCompanyFantasyNameById(companyId: number): string|number {
      const foundedCompany = this.companies.find(({ id }) => id === companyId);

      if (!foundedCompany) {
        return companyId;
      }

      return foundedCompany.fantasyName;
    },
    handleCreate(): void {
      this.$router.push({ name: 'IntegratorTableUpsert' });
    },
    handleEdit(table: string, companyId: number): void {
      this.$store.commit('integrator_config/setDataToUpdate', {
        table,
        companyId,
      });
      this.$router.push({ name: 'IntegratorTableUpsert' });
    },
    handleDelete(tablesIndex: number, tablesFieldIndex: number): void {
      this.removeIntegratorDialogOpen = true;
      this.removeIntegratorIndexes.tablesFieldIndex = tablesFieldIndex;
      this.removeIntegratorIndexes.tablesIndex = tablesIndex;
    },
    handleCloseRemove(): void {
      this.removeIntegratorDialogOpen = false;
      this.removeIntegratorIndexes.tablesFieldIndex = NaN;
      this.removeIntegratorIndexes.tablesIndex = NaN;
    },
    handleOnSaveRemove(tablesConfigurations: TableConfigurations[]): void {
      this.handleCloseRemove();

      this.tablesConfigList = tablesConfigurations;
      this.$store.commit('integrator_config/setTableFilesData', tablesConfigurations);
    },
    handleToggle(table: TableConfigurations): void {
      table.expanded = !table.expanded;
    },
	},
});
