






























































import { defineComponent } from '@vue/composition-api';
import ERPRepository from '@/repositories/ERPRepository';
import AccountsLogRepository from '@/repositories/AccountsLogRepository';
import DialogHelper from '@/helpers/DialogHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import PredictedFlowHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowHelper';
import { VForm } from '@/types/VForm';
import AccountActionsEnum from '@/repositories/parameters/AccountLogs/enums/AccountActionsEnum';
import PredictedFlowActionsEnum from '@/views/cashFlow/predictedFlow/enums/PredictedFlowActionsEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import IPredictedFlowGrouperItemData from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperItemData';
import ActionsWithoutReloadEnum from '../../enums/ActionsWithoutReloadEnum';

export default defineComponent({
  name: 'IncludeCommentDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object as () => IPredictedFlowGrouperList,
    },
    itemIndex: {
      required: false,
      type: Number,
    },
    totalIndex: {
      required: false,
      type: Number,
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
    itemData(): IPredictedFlowGrouperItemData {
      return this.register!.itemData!;
    },
    grouperType(): CashFlowTablesEnum {
      return this.register!.grouperType;
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const inputRules: InputRulesHelper = new InputRulesHelper();
    const erpRepository: ERPRepository = new ERPRepository();
    const accountsLogRepository: AccountsLogRepository = new AccountsLogRepository();

    const comment: string = '';

    const loading: boolean = false;
    const isOpen: boolean = this.value;

    return {
      inputRules,
      erpRepository,
      accountsLogRepository,
      comment,
      loading,
      isOpen,
    };
  },
  created() {
    if (
      this.register
      && this.totalIndex != undefined
      && this.register.totals[this.totalIndex]
    ) {
      this.comment = this.register.totals[this.totalIndex].comment ?? '';
    }
  },
  methods: {
    async includeComment(): Promise<void> {
      try {
        this.loading = true;

        await this.erpRepository.saveDataInternalTable({
          _data: this.comment,
          _date: this.register!.totals[this.totalIndex!].date,
          _field: 'comentario',
          _identifyValue: PredictedFlowHelper.getTableAliasByGrouperType(this.grouperType),
          _keyId: this.itemData.keyId,
        });

        if (['account_receivable', 'account_payable'].includes(this.tableName as string)) {
          this.accountsLogRepository.createLog(
            this.companyGroupId,
            {
              type: this.tableName === 'account_receivable' ? 'receivable' : 'payable',
              company_id: this.itemData.companyId,
              action: AccountActionsEnum.ADD_COMMENT,
              content: 'Adição de Comentário feita com sucesso.',
              id_customer: this.itemData.idCustomer,
            },
          );
        }

        this.$store.commit('predicted_flow/triggerEventSend', {
          action: ActionsWithoutReloadEnum.change_comment,
          itemIndex: this.itemIndex,
          data: {
            value: this.comment,
            totalIndex: this.totalIndex,
          },
        });

        this.$notification.success('Comentário incluido com sucesso!');
        this.close();
      } catch (error) {
        this.$notification.error('Houve um problema ao incluir o comentário.');
      } finally {
        this.loading = false;
      }
    },
    close(): void {
      this.$emit('close', PredictedFlowActionsEnum.include_comment);
    },
    handleIncludeComment(): void {
      const includeCommentForm = this.$refs.IncludeCommentForm as VForm;

      if (!includeCommentForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return;
      }

      this.includeComment();
    },
  },
});
