export default class CompanyGroupConfiguration {
  constructor(
    public shouldConsiderInitialBalanceOnPredictedFlow: boolean,
  ) { }

  static make(data: any): CompanyGroupConfiguration {
    return new CompanyGroupConfiguration(
      data.should_consider_initial_balance_on_predicted_flow,
    );
  }
}
