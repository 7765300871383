export default class SensediaConfiguration {
	constructor(
		public clientSecret: string,
		public clientId: string,
		public redirectUri: string,
		public grantCodeUri: string,
		public accessTokenUri: string,
		public integrationUri: string,
	) { }

	static make(data: any): SensediaConfiguration {
		return new SensediaConfiguration(
			data.client_id,
			data.client_secret,
			data.redirect_uri,
			data.grant_code_uri,
			data.access_token_uri,
			data.integration_uri,
		);
	}
}
