export default class DatabaseConnectionConfiguration {
	constructor(
        public customerSystem: string,
        public customerClient: string,
        public customerUser: string,
        public customerPort: string,
        public customerHost: string,
        public customerPassword: string,
        public customerDatabase: string,
        public customerConnectString: string,
        public inncashDatabase: string,
        public inncashControlTable: string,
        public inncashTableJob: string,
        public inncashUpdateControlTable: string,
	) { }

	static make(data: any): DatabaseConnectionConfiguration {
		return new DatabaseConnectionConfiguration(
            data.customer_system,
            data.customer_client,
            data.customer_user,
            data.customer_port,
            data.customer_host,
            data.customer_password,
            data.customer_database,
            data.customer_connect_string,
            data.inncash_database,
            data.inncash_control_table,
            data.inncash_table_job,
            data.inncash_update_control_table,
		);
	}
}
