export default class FeatureFlagConfiguration {
	constructor(
		public useInnCash: boolean,
		public useSensedia: boolean,
	) { }

	static make(data: any): FeatureFlagConfiguration {
		return new FeatureFlagConfiguration(
            data.use_inncash,
            data.use_sensedia,
		);
	}
}
