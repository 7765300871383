




















































































































































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import IntegratorDatabaseConnectionConfig from '@/views/integratorConfig/interfaces/forms/IntegratorDatabaseConnectionConfig';
import IntegratorDatabaseConnectionConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorDatabaseConnectionConfigRequest';
import { VForm } from '@/types/VForm';

export default defineComponent({
  name: 'IntegratorDatabaseConnectionConfigTab',
  data() {
    const inputRules: InputRulesHelper = new InputRulesHelper();
    const integratorRepository:
      IntegratorRepository = new IntegratorRepository();

    const databaseConnectionData: IntegratorDatabaseConnectionConfig = {
      customerSystem: '',
      customerClient: '',
      customerUser: '',
      customerPort: '',
      customerHost: '',
      customerPassword: '',
      customerDatabase: '',
      customerConnectString: '',
      inncashDatabase: '',
      inncashControlTable: '',
      inncashTableJob: '',
      inncashUpdateControlTable: '',
    };

    const loading: boolean = false;

    return {
      inputRules,
      integratorRepository,
      databaseConnectionData,
      loading,
    };
  },
  computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
  },
  created() {
    this.getDatabaseConnectionConfigData();
  },
  methods: {
    async getDatabaseConnectionConfigData(): Promise<void> {
      try {
        this.loading = true;

        const data = await this.integratorRepository
          .getDatabaseConnectionConfiguration(this.companyGroupId);

        this.databaseConnectionData = {
          customerSystem: data.customerSystem,
          customerClient: data.customerClient,
          customerUser: data.customerUser,
          customerPort: data.customerPort,
          customerHost: data.customerHost,
          customerPassword: data.customerPassword,
          customerDatabase: data.customerDatabase,
          customerConnectString: data.customerConnectString,
          inncashDatabase: data.inncashDatabase,
          inncashControlTable: data.inncashControlTable,
          inncashTableJob: data.inncashTableJob,
          inncashUpdateControlTable: data.inncashUpdateControlTable,
        };
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os dados de configuração de conexão do banco de dados.');
      } finally {
        this.loading = false;
      }
    },
    async handleClickSaveDatabaseConnectionConfig(): Promise<void> {
      try {
        this.$dialog.startLoading();

        const requestData = this.validateFormAndGetValuesRequest();

        if (!requestData) {
          return;
        }

        await this.integratorRepository
          .setDatabaseConnectionConfiguration(this.companyGroupId, requestData);

        this.$notification.success('Configuração de Conexão com o banco gravada com sucesso.');
      } catch (error) {
        this.$notification.error('Houve um problema ao salvar o arquivo de configuração do Sinalizador de Recurso');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    validateFormAndGetValuesRequest(): IntegratorDatabaseConnectionConfigRequest | null {
      const databaseConnectionForm = this.$refs.IntegratorDatabaseConnectionConfigForm as VForm;

      if (!databaseConnectionForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return null;
      }

      return {
        customer_system: this.databaseConnectionData.customerSystem,
        customer_client: this.databaseConnectionData.customerClient,
        customer_user: this.databaseConnectionData.customerUser,
        customer_port: this.databaseConnectionData.customerPort,
        customer_host: this.databaseConnectionData.customerHost,
        customer_password: this.databaseConnectionData.customerPassword,
        customer_database: this.databaseConnectionData.customerDatabase,
        customer_connect_string: this.databaseConnectionData.customerConnectString,
        inncash_database: this.databaseConnectionData.inncashDatabase,
        inncash_control_table: this.databaseConnectionData.inncashControlTable,
        inncash_table_job: this.databaseConnectionData.inncashTableJob,
        inncash_update_control_table: this.databaseConnectionData.inncashUpdateControlTable,
      };
    },
  },
});
