enum AccountActionsEnum {
  REMOVE_FROM_REMITTANCE = 'remove_from_remittance',
  REMITTANCE_INCLUSION = 'remittance_inclusion',
  SEND_REMITTANCE_API = 'send_remittance_api',
  CHANGE_DATE = 'change_date',
  CHANGE_CARRIER = 'change_carrier',
  CHANGE_VALUE = 'change_value',
  CHANGE_RECEIVE_FORM = 'change_receive_form',
  CHANGE_PAYMENT_DATA = 'change_payment_data',
  CHANGE_PAYMENT_FORM = 'change_payment_form',
  ADD_INCREASE = 'add_increase',
  ADD_DECREASE = 'add_decrease',
  ADD_COMMENT = 'add_comment',
}

export default AccountActionsEnum;
