import FileIdentifierType from '@/types/FileIdentifierType';
import PrepareFileToDownloadException from '@/helpers/exceptions/PrepareFileToDownloadException';

export default class FileHelper {
  public static appendFilesToFormData(
      files: FileIdentifierType|FileIdentifierType[],
      specificIdentifier: string|null = null,
  ): FormData {
      let filesToTransverse: FileIdentifierType[];

      if (!Array.isArray(files)) {
          filesToTransverse = [files];
      } else {
          filesToTransverse = files;
      }

      const formData = new FormData();

      for (let i = 0; i < filesToTransverse.length; i += 1) {
          const actualFile = filesToTransverse[i];

          const fileIdentifier = actualFile.identifier ?? actualFile.file.name;

          formData.append(specificIdentifier ?? fileIdentifier, actualFile.file);
      }

      return formData;
  }

  public static async blobToBase64(blob: Blob): Promise<string> {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(blob);

    const fileReaderResolver = new Promise<string>((resolve, reject) => {
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target === null) {
          reject(new PrepareFileToDownloadException());

          return;
        }

        resolve(event.target.result as string);
      };
    });

    return fileReaderResolver;
  }

  public static blobToUrl(blobFile: Blob): string {
    return URL.createObjectURL(blobFile);
  }

  public static async openPrintScreenOfPdf(blobFile: Blob): Promise<void> {
    window.open(URL.createObjectURL(blobFile));
  }

  public static extractOnlyBase64Code(base64String: string): string {
    return base64String.substring(base64String.indexOf('base64') + 7);
  }

  public static downloadFileByObjectUrl(objectUrl: string, filename: string): void {
    const element = document.createElement('a');

    element.href = objectUrl;
    element.setAttribute('download', filename);

    document.body.appendChild(element);

    element.click();
  }
}
