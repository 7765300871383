export default class CompanyAndBranchConfiguration {
	constructor(
        public branchCode: string,
        public companyCode: string,
        public companyId: number,
	) { }

	static make(data: any): CompanyAndBranchConfiguration {
		return new CompanyAndBranchConfiguration(
            data.branch_code,
            data.company_code,
            data.company_id,
		);
	}
}
