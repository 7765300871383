import ICurrencyAditionalOptions from '@/helpers/interfaces/ICurrencyAditionalOptions';

export default class CurrencyHelper {
  private static readonly defaultCurrency = 'pt-BR';

  public static toCurrency(
    value: string|number|undefined|null,
    returnOptions: ICurrencyAditionalOptions | undefined = undefined,
    config: Intl.NumberFormatOptions | undefined = undefined,
  ): string {
    if (value === undefined || value === null) {
      return '';
    }

    const parsedNumber = Number(value);

    if (Number.isNaN(parsedNumber)) {
      return '';
    }

    const defaultConfigs: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    };

    const formattedNumber = new Intl.NumberFormat('pt-BR', Object.assign(defaultConfigs, config));

    if (!returnOptions?.showCurrencySymbol) {
      const { maximumFractionDigits } = formattedNumber.resolvedOptions();

      return (parsedNumber).toLocaleString(this.defaultCurrency, { maximumFractionDigits });
    }

    return formattedNumber.format(parsedNumber);
  }

  public static getCurrencyPrefixBasedOnId(coinId: number|string): string {
    switch (coinId) {
      case 1: return 'R$';
      case 2: return 'US$';
      case 4: return '€';
      case 22: return 'CUBE';
      case 23: return 'GPM';
      case 'R$': return 'R$';
      default: return 'R$';
    }
  }
}
