import {
  DateTime,
  DurationObjectUnits,
  ToObjectOutput,
  Interval,
} from 'luxon';

export const ptBrDateFormat = 'dd/MM/yyyy';
export const ptBrDateTimeFormat = 'dd/MM/yyyy HH:mm';
export const defaultDateFormat = 'yyyy-MM-dd';
export const dateTimeFormat = 'yyyy-MM-dd HH:mm';
export const dateTimeFormatWithSeconds = 'yyyy-MM-dd HH:mm:ss';
export const timeFormat = 'HH:mm';

export function dateNow(): string {
  return DateTime.now().toISODate();
}

export function formateDate(date: string, toFormat: string = ptBrDateFormat): string {
  return DateTime.fromISO(date).toFormat(toFormat);
}

export function formatMonth(date: string): string {
  return DateTime.fromISO(date).toFormat('MM/yyyy');
}

export function formateDateToISO(date: string): string {
  return DateTime.fromFormat(date, ptBrDateFormat).toISODate();
}

export function formatToSpecificFormat(
  date: string,
  toFormat: string,
  fromFormat: string = defaultDateFormat,
): string {
  return DateTime
    .fromFormat(date, fromFormat, { locale: 'pt' })
    .toFormat(toFormat);
}

export function formatManuallyToPtBrFormat(date: string): string {
  const dateArray = date.split('-');
  return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
}

export function getObjectDate(
  date: string,
  fromFormat: string = defaultDateFormat,
): ToObjectOutput {
  return DateTime
    .fromFormat(date, fromFormat, { locale: 'pt' })
    .toObject();
}

export function getEndOf(
  date: string,
  unit: 'day' | 'week' | 'month',
  fromFormat: string = defaultDateFormat,
  toFormat: string = defaultDateFormat,
): string {
  let dateTime = DateTime
    .fromFormat(date, fromFormat, { locale: 'pt' })
    .endOf(unit);

  if (unit === 'week') {
    dateTime = dateTime.minus({ days: 1 });
  }

  return dateTime.toFormat(toFormat);
}

export function getStartOf(
  date: string,
  unit: 'day' | 'week' | 'month',
  fromFormat: string = defaultDateFormat,
  toFormat: string = defaultDateFormat,
): string {
  let dateTime = DateTime
    .fromFormat(date, fromFormat, { locale: 'pt' })
    .startOf(unit);

  if (unit === 'week') {
    dateTime = dateTime.minus({ days: 1 });
  }

  return dateTime.toFormat(toFormat);
}

export function validateDateRange(initial: string, end: string): boolean {
  const initialDate = DateTime.fromISO(initial);
  const endDate = DateTime.fromISO(end);

  return endDate.diff(initialDate, 'days').days >= 0;
}

export function dateEqualToday(
  date: string,
  fromFormat: string = defaultDateFormat,
): boolean {
  const instantiatedDate = DateTime.fromFormat(date, fromFormat);
  const nowDate = DateTime.now();

  return instantiatedDate.hasSame(nowDate, 'day');
}

export function dateGreaterThanToday(
  date: string,
  fromFormat: string = defaultDateFormat,
): boolean {
  const instantiatedDate = DateTime.fromFormat(date, fromFormat);
  const nowDate = DateTime.now();

  return instantiatedDate.startOf('day') > nowDate.startOf('day');
}

export function dateGreaterOrEqualToday(
  value: string,
  format: string = defaultDateFormat,
): boolean {
  if (value === undefined) return false;
  const today = DateTime.fromISO(dateNow());
  const date = DateTime.fromFormat(value, format);
  return date.diff(today, 'days').days >= 0;
}

export function dateGreaterOrEqualThanOtherDate(
  date: string,
  secondDate: string,
  fromFormat: string = defaultDateFormat,
): boolean {
  const formattedFirstDate = DateTime.fromFormat(date, fromFormat);
  const formattedSecondDate = DateTime.fromFormat(secondDate, fromFormat);

  return formattedFirstDate.startOf('day') >= formattedSecondDate.startOf('day');
}

export function dateLowerOrEqualThanOtherDate(
  date: string,
  secondDate: string,
  fromFormat: string = defaultDateFormat,
): boolean {
  const formattedFirstDate = DateTime.fromFormat(date, fromFormat);
  const formattedSecondDate = DateTime.fromFormat(secondDate, fromFormat);

  return formattedFirstDate.startOf('day') <= formattedSecondDate.startOf('day');
}

export function dateTimeLowerOrEqualThanOther(
  date: string,
  secondDate: string,
  fromFormat: string = dateTimeFormat,
): boolean {
  const formattedFirstDate = DateTime.fromFormat(date, fromFormat);
  const formattedSecondDate = DateTime.fromFormat(secondDate, fromFormat);

  return formattedFirstDate <= formattedSecondDate;
}

export function monthsDistanceBetweenTwoDates(initial: string, end: string): number {
  const initialDate = DateTime.fromISO(initial);
  const endDate = DateTime.fromISO(end);

  return endDate.diff(initialDate, 'months').months;
}

export function getMilis(date: string, format: string = defaultDateFormat): number {
  return DateTime.fromFormat(date, format).toMillis();
}

function getDateTimeInstance(date: string|DateTime): DateTime {
  if (typeof date === 'string') {
    return DateTime.fromFormat(date, defaultDateFormat, { locale: 'pt' });
  }

  return date;
}

export function getIncreasedDate(date: string|DateTime, interval: DurationObjectUnits): string {
  const dateTimeInstance = getDateTimeInstance(date);

  return dateTimeInstance
    .plus(interval)
    .toFormat(defaultDateFormat);
}

export function getDecreasedDate(date: string|DateTime, interval: DurationObjectUnits): string {
  const dateTimeInstance = getDateTimeInstance(date);

  return dateTimeInstance
    .minus(interval)
    .toFormat(defaultDateFormat);
}

export function isDateBetween(baseDate: string, startDate: string, finalDate: string): boolean {
  const interval = Interval.fromDateTimes(
    DateTime.fromFormat(startDate, defaultDateFormat).startOf('day'),
    DateTime.fromFormat(finalDate, defaultDateFormat).endOf('day'),
  );

  return interval.contains(DateTime.fromFormat(baseDate, defaultDateFormat));
}
