import Repository from '@/repositories/base/Repository';
import SensediaConfiguration from '@/domain/models/integrator/SensediaConfiguration';
import FeatureFlagConfiguration from '@/domain/models/integrator/FeatureFlagConfiguration';
import DatabaseConnectionConfiguration from '@/domain/models/integrator/DatabaseConnectionConfiguration';
import IntegratorSensediaConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorSensediaConfigRequest';
import IntegratorFeatureFlagConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorFeatureFlagConfigRequest';
import IntegratorDatabaseConnectionConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorDatabaseConnectionConfigRequest';
import InnCashCredentialsConfiguration from '@/domain/models/integrator/InnCashCredentialsConfiguration';
import IntegratorInnCashCredentialsConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorInnCashCredentialsConfigRequest';
import CompanyAndBranchConfiguration from '@/domain/models/integrator/CompanyAndBranchConfiguration';
import IntegratorBranchAndCompanyConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorBranchAndCompanyConfigRequest';
import RefreshAllManagementConfiguration from '@/domain/models/integrator/RefreshAllManagementConfiguration';
import IntegratorRefreshAllManagementConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorRefreshAllManagementConfigRequest';
import TableConfigurations from '@/domain/models/integrator/TableConfigurations';
import IntegratorTablesWithFieldsConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorTablesWithFieldsConfigRequest';

export default class IntegratorRepository extends Repository {
  async getSensediaConfiguration(companyGroupId: number): Promise<SensediaConfiguration> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/integrator/sensedia-configuration`);

    return SensediaConfiguration.make(response.data);
  }

  async getFeatureFlagConfiguration(companyGroupId: number): Promise<FeatureFlagConfiguration> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/integrator/feature-flag`);

    return FeatureFlagConfiguration.make(response.data);
  }

  async getDatabaseConnectionConfiguration(
    companyGroupId: number,
  ): Promise<DatabaseConnectionConfiguration> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/integrator/database-connection`);

    return DatabaseConnectionConfiguration.make(response.data);
  }

  async getInnCashCredentialsConfiguration(
    companyGroupId: number,
  ): Promise<InnCashCredentialsConfiguration> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/integrator/inncash-credentials`);

    return InnCashCredentialsConfiguration.make(response.data);
  }

  async getCompanyAndBranchConfiguration(
    companyGroupId: number,
  ): Promise<CompanyAndBranchConfiguration[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/integrator/company-and-branch`);

    return response.data.map(CompanyAndBranchConfiguration.make);
  }

  async getRefreshAllManagementConfiguration(
    companyGroupId: number,
  ): Promise<RefreshAllManagementConfiguration> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/integrator/refresh-all-management`);

    return RefreshAllManagementConfiguration.make(response.data);
  }

  async getTableAndFieldsConfiguration(
    companyGroupId: number,
  ): Promise<TableConfigurations[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/integrator/table-configurations`);

    return response.data.map(TableConfigurations.make);
  }

  async setSensediaConfiguration(
    companyGroupId: number,
    params: IntegratorSensediaConfigRequest,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/integrator/upload/sensedia-configuration`, params);
  }

  async setFeatureFlagConfiguration(
    companyGroupId: number,
    params: IntegratorFeatureFlagConfigRequest,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/integrator/upload/feature-flag-configuration`, params);
  }

  async setDatabaseConnectionConfiguration(
    companyGroupId: number,
    params: IntegratorDatabaseConnectionConfigRequest,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/integrator/upload/database-connection-configuration`, params);
  }

  async setInnCashCredentialsConfiguration(
    companyGroupId: number,
    params: IntegratorInnCashCredentialsConfigRequest,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/integrator/upload/inncash-credentials-configuration`, params);
  }

  async setBranchAndCompanyConfigurationList(
    companyGroupId: number,
    params: IntegratorBranchAndCompanyConfigRequest[],
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/integrator/upload/branch-code-and-company-code-configuration`, { companies: params });
  }

  async setRefreshAllManagementConfiguration(
    companyGroupId: number,
    params: IntegratorRefreshAllManagementConfigRequest,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/integrator/upload/refresh-all-management-configuration`, params);
  }

  async setTableConfiguration(
    companyGroupId: number,
    params: IntegratorTablesWithFieldsConfigRequest,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/integrator/upload/table-configuration`, params);
  }
}
