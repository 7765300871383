































































import { defineComponent } from '@vue/composition-api';
import { API_BASE } from '@config/api';
import { formateDate, ptBrDateTimeFormat } from '@/utils/date';
import ProcessManagementRepository from '@/repositories/ProcessManagementRepository';
import Notification from '@/domain/models/notifications/Notification';
import FileHelper from '@/helpers/FileHelper';

export default defineComponent({
  name: 'Notification',
  data() {
    const notifications: Notification[] = [];

    const processManagementRepository:
      ProcessManagementRepository = new ProcessManagementRepository();

    const eventSource: EventSource|undefined = undefined;

    return {
      notifications,
      processManagementRepository,
      eventSource: eventSource as EventSource|undefined,
    };
  },
  computed: {
    companyGroupId(): number {
      return this.$session.get('company_group_id');
    },
    notificationsNotViewed(): number {
      return this.notifications.filter(({ wasViewed }) => !wasViewed).length;
    },
  },
  created() {
    this.setConnectionSSEWithNotification();
  },
  beforeDestroy() {
    this.eventSource!.close();
  },
  methods: {
    setConnectionSSEWithNotification(): void {
      const token: string = this.$session.get('token');
      this.eventSource = new EventSource(
        `${API_BASE}company-groups/${this.companyGroupId}/process-management/sse?token=${token}`,
      );

      this.eventSource.addEventListener('process', (event) => {
          const messageEvent = event as MessageEvent;
         const eventDataJson: any[] = JSON.parse(messageEvent.data);

        if (eventDataJson) {
          this.notifications = eventDataJson.map(Notification.make);
        }
      });
    },
    async downloadFileByURL(id: number): Promise<void> {
      try {
        this.$dialog.startLoading();

        const result = await this.processManagementRepository.downloadFileByProcessId(
          this.companyGroupId,
          id,
        );

        const urlFile = FileHelper.blobToUrl(result.blob);

        FileHelper.downloadFileByObjectUrl(urlFile, result.fileName);

        const selectedNotificationIndex = this.notifications
          .findIndex((notification) => notification.id === id);

        if (selectedNotificationIndex > -1) {
          this.notifications = this.notifications
            .filter((_, index) => index !== selectedNotificationIndex);
        }
      } catch (error) {
        this.$notification.error('Houve um problema ao atualizar o status do processo');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    formatDate(date: string): string {
      if (!date) {
        return '';
      }

      return formateDate(date, ptBrDateTimeFormat);
    },
    async handleViewNotifications(): Promise<void> {
      try {
        const notViewedNotifications = this.notifications
          .filter(({ wasViewed }) => !wasViewed);

        if (notViewedNotifications.length < 1) {
          return;
        }

        await this.processManagementRepository.setNotificationsAsViewedByIds(
          this.companyGroupId,
          notViewedNotifications.map(({ id }) => id),
        );

        for (let index = 0; index < notViewedNotifications.length; index += 1) {
          notViewedNotifications[index].wasViewed = true;
        }
      } catch (error) {
        this.$notification.error('Houve um problema ao atualizar o estado das notificações.');
      }
    },
  },
});
