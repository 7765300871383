import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-7",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"button-width",attrs:{"color":"success","large":"","loading":_vm.loading},on:{"click":_vm.handleClickSave}},[_vm._v(" SALVAR ")])],1)],1),_c(VForm,{ref:"IntegratorBranchAndCompanyConfigForm"},[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.companyOptions,"disabled":_vm.loading || _vm.actualRegisterIndex !== undefined,"label":"Empresa","placeholder":"Selecione uma empresa","height":"56px","filled":"","dense":""},model:{value:(_vm.companyAndBranchFormData.companyId),callback:function ($$v) {_vm.$set(_vm.companyAndBranchFormData, "companyId", $$v)},expression:"companyAndBranchFormData.companyId"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"label":"Código de filial","filled":"","height":"56px","loading":_vm.loading,"rules":[
              _vm.inputRules.required(),
              _vm.inputRules.lowerOrEqualThen(6) ]},model:{value:(_vm.companyAndBranchFormData.branchCode),callback:function ($$v) {_vm.$set(_vm.companyAndBranchFormData, "branchCode", $$v)},expression:"companyAndBranchFormData.branchCode"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"label":"Código de Empresa","filled":"","height":"56px","loading":_vm.loading,"rules":[
              _vm.inputRules.required(),
              _vm.inputRules.lowerOrEqualThen(6) ]},model:{value:(_vm.companyAndBranchFormData.companyCode),callback:function ($$v) {_vm.$set(_vm.companyAndBranchFormData, "companyCode", $$v)},expression:"companyAndBranchFormData.companyCode"}})],1),_c(VCol,{attrs:{"cols":"auto","align-self":"center"}},[_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-plus","tooltip-text":"Adicionar regra"},on:{"click":_vm.handleAddBranchWithCompany}})],1)],1)],1),_c(VRow,[_c(VCol,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Empresa")]),_c('th',{staticClass:"text-left"},[_vm._v("Código de Filial")]),_c('th',{staticClass:"text-left"},[_vm._v("Código de Empresa")]),_c('th')])]),_c('tbody',_vm._l((_vm.companyCodeAndBranchList),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.getCompanyNameById(item.companyId)))]),_c('td',[_vm._v(_vm._s(item.branchCode))]),_c('td',[_vm._v(_vm._s(item.companyCode))]),_c('td',{staticClass:"text-right"},[_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-pencil","tooltip-text":"Editar"},on:{"click":function($event){return _vm.handleEdit(index, item)}}}),_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-delete","tooltip-text":"Excluir"},on:{"click":function($event){return _vm.handleRemove(index)}}})],1)])}),0)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }