import Company from '@/domain/models/Company';
import TableConfigurations from '@/domain/models/integrator/TableConfigurations';
import IIntegratorTablesConfig from '@/views/integratorConfig/interfaces/forms/IIntegratorTablesConfig';
import IIntegratorTablesDataToUpdate from '@/views/integratorConfig/interfaces/stores/IIntegratorTablesDataToUpdate';

export default {
  namespaced: true,
  state: {
    companies: [],
    selectedDataToUpdate: {
      companyId: NaN,
      table: '',
    },
    tableFilesData: [],
  },
  mutations: {
    setCompanies(state: any, companies: Company[]) {
      state.companies = companies;
    },
    setDataToUpdate(state: any, data: IIntegratorTablesDataToUpdate) {
      state.selectedDataToUpdate = {
        companyId: data.companyId,
        table: data.table,
      };
    },
    setTableFilesData(state: any, tablesData: TableConfigurations[]) {
      state.tableFilesData = tablesData;
    },
  },
  getters: {
    getSpecificTableFromTablesList: (state: any) => (tableName: string) => state.tableFilesData
        .find((table: TableConfigurations) => table.tableName === tableName),
    getSpecificTableCompanyFromTablesList: (state: any) => (
      filter: IIntegratorTablesDataToUpdate,
    ) => {
      const foundedTable = state.tableFilesData
        .find((table: TableConfigurations) => table.tableName === filter.table);

      if (!foundedTable) {
        return null;
      }

      return foundedTable.tablesByCompanies.find(
        (tableCompanyData: IIntegratorTablesConfig) => (
          tableCompanyData.companyId === filter.companyId
        ),
      );
    },
  },
};
