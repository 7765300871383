import DownloadBlobWithFile from '@/domain/models/DownloadBlobWithFile';
import Repository from '@/repositories/base/Repository';

export default class ProcessManagementRepository extends Repository {
  async downloadFileByProcessId(
    companyGroupId: number,
    processId: number,
  ): Promise<DownloadBlobWithFile> {
    const { data: response, headers } = await this.datasource.get(`company-groups/${companyGroupId}/process-management/download/${processId}`, {
      responseType: 'blob',
    });

    return DownloadBlobWithFile.make(response, headers);
  }

  async setNotificationsAsViewedByIds(
    companyGroupId: number,
    processIds: number[],
  ): Promise<void> {
    await this.datasource.update(
      `company-groups/${companyGroupId}/process-management/viewed-state`,
      { ids: processIds },
    );
  }
}
