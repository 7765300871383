export enum IntegratorTableName {
  CUSTOMER = 'customer',
  CUSTOMER_NCC = 'customer_ncc',
  SUPPLIER = 'provider',
  BANK_BALANCE = 'bank_balance',
  ACCOUNT_PAYABLE = 'account_payable',
  ACCOUNT_RECEIVABLE = 'account_receivable',
  ACCOUNT_RECEIVABLE_NCC = 'account_receivable_ncc',
  FINANCIAL_NATURE = 'financial_nature',
  BANK = 'bank',
  COST_CENTER = 'cost_center',
  VALUE_CLASS = 'value_class',
  CURRENCY = 'currency',
  ACCOUNTING_ITEM = 'accounting_item',
  BANK_TRANSACTION = 'bank_transaction',
}

export default IntegratorTableName;
