import IntegratorTableNameEnum from '@/views/integratorConfig/enums/IntegratorTableNameEnum';
import IIntegratorTablesConfig from '@/views/integratorConfig/interfaces/forms/IIntegratorTablesConfig';

export default class TableConfigurations {
	constructor(
		public tableName: IntegratorTableNameEnum,
		public tablesByCompanies: IIntegratorTablesConfig[],
		public expanded: boolean,
	) { }

	static make(data: any): TableConfigurations {
		return new TableConfigurations(
			data.table_name,
			data.table_config_company,
			false,
		);
	}
}
