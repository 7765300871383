import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-7",attrs:{"fluid":""}},[_c(VForm,{ref:"IntegratorInnCashCredentialsConfigForm"},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Url do InnCash","filled":"","height":"56px","loading":_vm.loading,"rules":[
							_vm.inputRules.required(),
							_vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.innCashCredentialsConfigData.url),callback:function ($$v) {_vm.$set(_vm.innCashCredentialsConfigData, "url", $$v)},expression:"innCashCredentialsConfigData.url"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Login","filled":"","height":"56px","loading":_vm.loading,"rules":[
							_vm.inputRules.required(),
							_vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.innCashCredentialsConfigData.email),callback:function ($$v) {_vm.$set(_vm.innCashCredentialsConfigData, "email", $$v)},expression:"innCashCredentialsConfigData.email"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Senha","filled":"","height":"56px","loading":_vm.loading,"rules":[
							_vm.inputRules.required(),
							_vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.innCashCredentialsConfigData.password),callback:function ($$v) {_vm.$set(_vm.innCashCredentialsConfigData, "password", $$v)},expression:"innCashCredentialsConfigData.password"}})],1)],1)],1),_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"button-width",attrs:{"color":"success","large":"","loading":_vm.loading},on:{"click":_vm.handleClickSaveInnCashCredentialsConfig}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }