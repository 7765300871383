import AccountDetails from '@/domain/models/cashFlow/new/AccountDetails';
import Repository from './base/Repository';

export default class AccountReceivableRepository extends Repository {
  public async find(
    id: string,
    companyGroupId: number,
    companyId: number,
    date: string,
  ): Promise<AccountDetails> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-receivable/${id}`,
      {
        params: { date },
        headers: { 'company-id': JSON.stringify(companyId) },
      },
    );

    return AccountDetails.make(response.data);
  }
}
