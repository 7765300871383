import IIntegratorTablesConfig from '@/views/integratorConfig/interfaces/forms/IIntegratorTablesConfig';
import IIntegratorTablesConfigRequest from '@/views/integratorConfig/interfaces/requests/IIntegratorTablesConfigRequest';
import IntegratorTablesWithFieldsConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorTablesWithFieldsConfigRequest';

export default class IntegratorHelper {
  public static getFormattedTableConfigForRequest(
    tableConfig: IIntegratorTablesConfig,
  ): IIntegratorTablesConfigRequest {
    return {
      name: tableConfig.name,
      filter: tableConfig.filter,
      filter_delete: tableConfig.filterDelete,
      group_name: tableConfig.groupName,
      table_name: tableConfig.tableName,
      company_id: tableConfig.companyId,
      enable_ncc: tableConfig.enableNCC,
      inncash_identifier_field: tableConfig.inncashIdentifierField,
      refresh_all: tableConfig.refreshAll,
      is_considering_default_fields: tableConfig.isConsideringDefaultFields,
      is_default_fields: tableConfig.isDefaultFields,
      fields: tableConfig.fields.map((field) => ({
        inncash_api_field_types: field.inncashApiFieldTypes,
        inncash_api_field_name: field.inncashApiFieldName,
        innmerge_api_field_name: field.innmergeApiFieldName,
        database_field_name: field.databaseFieldName,
        update_field_format: field.updateFieldFormat,
      })),
    };
  }
}
