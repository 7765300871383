






















































































































































































































































































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import Company from '@/domain/models/Company';
import IntegratorHelper from '@/views/integratorConfig/helpers/IntegratorHelper';
import IIntegratorTablesConfig from '@/views/integratorConfig/interfaces/forms/IIntegratorTablesConfig';
import IIntegratorTablesFieldsConfig from '@/views/integratorConfig/interfaces/forms/IIntegratorTablesFieldsConfig';
import IntegratorTableNameEnum from '@/views/integratorConfig/enums/IntegratorTableNameEnum';
import IIntegratorTablesDataToUpdate from '@/views/integratorConfig/interfaces/stores/IIntegratorTablesDataToUpdate';
import IntegratorTablesWithFieldsConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorTablesWithFieldsConfigRequest';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import { VForm } from '@/types/VForm';
import TableConfigurations from '@/domain/models/integrator/TableConfigurations';
import IIntegratorTablesConfigRequest from '../interfaces/requests/IIntegratorTablesConfigRequest';

export default defineComponent({
	name: 'IntegratorTableUpsert',
	data() {
		const inputRules: InputRulesHelper = new InputRulesHelper();
		const integratorRepository: IntegratorRepository = new IntegratorRepository();

    const selectedTable: IntegratorTableNameEnum|undefined = undefined;

		const tablesConfigData: IIntegratorTablesConfig = {
			name: '',
			filter: '',
      filterDelete: '',
			groupName: '',
			tableName: '',
      inncashIdentifierField: '',
			companyId: NaN,
			enableNCC: false,
			refreshAll: false,
      isConsideringDefaultFields: false,
      isDefaultFields: false,
			fields: [],
		};
		const actualField: IIntegratorTablesFieldsConfig = {
			inncashApiFieldTypes: [],
			innmergeApiFieldName: '',
			inncashApiFieldName: '',
			databaseFieldName: '',
			updateFieldFormat: '',
		};

		const fieldTypeOptions: ISelectOptions<string>[] = [
			{ value: 'string', text: 'Texto' },
			{ value: 'date', text: 'Data' },
		];
    const tableOptions: ISelectOptions<IntegratorTableNameEnum>[] = [
        { value: IntegratorTableNameEnum.CUSTOMER, text: 'Cliente' },
        { value: IntegratorTableNameEnum.CUSTOMER_NCC, text: 'Cliente NCC' },
        { value: IntegratorTableNameEnum.SUPPLIER, text: 'Fornecedor' },
        { value: IntegratorTableNameEnum.BANK_BALANCE, text: 'Saldo Bancário' },
        { value: IntegratorTableNameEnum.ACCOUNT_PAYABLE, text: 'Conta a Pagar' },
        { value: IntegratorTableNameEnum.ACCOUNT_RECEIVABLE, text: 'Conta a Receber' },
        { value: IntegratorTableNameEnum.ACCOUNT_RECEIVABLE_NCC, text: 'Conta a Receber NCC' },
        { value: IntegratorTableNameEnum.FINANCIAL_NATURE, text: 'Natureza Financeira' },
        { value: IntegratorTableNameEnum.BANK, text: 'Banco' },
        { value: IntegratorTableNameEnum.COST_CENTER, text: 'Centro de Custo' },
        { value: IntegratorTableNameEnum.VALUE_CLASS, text: 'Valor de Classe' },
        { value: IntegratorTableNameEnum.CURRENCY, text: 'Moeda' },
        { value: IntegratorTableNameEnum.ACCOUNTING_ITEM, text: 'Item Contábil' },
        { value: IntegratorTableNameEnum.BANK_TRANSACTION, text: 'Transação Bancária' },
    ];

    const insertedFields: IIntegratorTablesFieldsConfig[] = [];

		const loading: boolean = false;

		return {
      inputRules,
      integratorRepository,
      selectedTable: selectedTable as IntegratorTableNameEnum|undefined,
			tablesConfigData,
			actualField,
			fieldTypeOptions,
      tableOptions,
      insertedFields,
			loading,
		};
	},
  computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
      companies: (state: any): Company[] => state.integrator_config.companies,
      tableFilesData: (state: any): TableConfigurations[] => state.integrator_config.tableFilesData,
      selectedDataToUpdate: (state: any): IIntegratorTablesDataToUpdate => state
        .integrator_config.selectedDataToUpdate,
    }),
    companyOptions(): ISelectOptions<number>[] {
      if (!this.selectedTable) {
        return [];
      }

      const formattedCompanies = this.companies.map(({ id, fantasyName }) => ({
        value: id,
        text: fantasyName,
      }));

      if (this.isEditing) {
        return formattedCompanies;
      }

      const companyIdsCreatedFromTable = this.tableFilesData
        .map(({ tableName, tablesByCompanies }) => ({
          tableName,
          companyIds: tablesByCompanies.map(({ companyId }) => companyId),
        }))
        .find(({ tableName }) => tableName === this.selectedTable);

      if (!companyIdsCreatedFromTable) {
        return formattedCompanies;
      }

      return formattedCompanies
        .filter(({ value }) => !companyIdsCreatedFromTable.companyIds.includes(value));
    },
    isEditing(): boolean {
      if (!Number.isNaN(this.selectedDataToUpdate.companyId) && this.selectedDataToUpdate.table) {
        return true;
      }

      return false;
    },
    isUsingDefaultFields(): boolean {
      return this.tablesConfigData.isConsideringDefaultFields;
    },
    pageTitle(): string {
      const actionText = this.isEditing ? 'Editar' : 'Criar';

      return `${actionText} Configuração de Tabela do Integrador`;
    },
    actionButtonText(): string {
      return this.isEditing ? 'EDITAR' : 'SALVAR';
    },
    companyOptionsDisabled(): boolean {
      return !this.selectedTable || this.isEditing;
    },
  },
	created() {
    if (this.companies.length < 1) {
      this.$notification.warn('Nenhuma empresa foi encontrada.');
      this.handleCancel();
    }

    if (this.isEditing) {
      const tableCompanyEdited: IIntegratorTablesConfig = this.$store.getters['integrator_config/getSpecificTableCompanyFromTablesList'](this.selectedDataToUpdate);

      this.tablesConfigData = {
        ...tableCompanyEdited,
      };
      this.insertedFields = tableCompanyEdited.fields;
      this.selectedTable = this.selectedDataToUpdate.table as IntegratorTableNameEnum;
    }
  },
	methods: {
    resetActualField(): void {
      this.actualField = {
        inncashApiFieldTypes: [],
        innmergeApiFieldName: '',
        inncashApiFieldName: '',
        databaseFieldName: '',
        updateFieldFormat: '',
      };
    },
    getTextFromType(types: string[]): string {
      const formattedTypesText = types.map((type) => {
        switch (type) {
          case 'string': return 'Texto';
          case 'date': return 'Data';
          default: return '';
        }
      });

      return formattedTypesText.join(' | ');
    },
    async saveTableOnFile(tableData: IntegratorTablesWithFieldsConfigRequest): Promise<void> {
      try {
        this.$dialog.startLoading();

        await this.integratorRepository.setTableConfiguration(this.companyGroupId, tableData);

        this.$store.commit('integrator_config/setDataToUpdate', {
          companyId: NaN,
          table: '',
        });

        this.$router.push({ name: 'IntegratorConfig' });
        this.$notification.success('Configuração de Tabela gravado com sucesso.');
      } catch (error) {
        this.$notification.error('Houve um problema ao enviar os dados do arquivo de tabela.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    handleCancel(): void {
      this.$store.commit('integrator_config/setDataToUpdate', {
        companyId: NaN,
        table: '',
      });

      this.$router.push({ name: 'IntegratorConfig' });
    },
    handleAddField(): void {
			const form = this.$refs.IntegratorConfigFieldForm as VForm;

			if (!form.validate()) {
				this.$notification.error('Há campos preenchidos incorretamente!');

        return;
			}

      this.insertedFields.push({ ...this.actualField });

      this.resetActualField();

      form.resetValidation();
    },
    handleEditField(index: number): void {
      this.actualField = { ...this.insertedFields[index] };
      this.insertedFields.splice(index, 1);
    },
    handleRemoveField(index: number): void {
      this.insertedFields.splice(index, 1);
    },
    handleSave(): void {
      const formattedIntegratorTablesRequest: IIntegratorTablesConfigRequest[] = [
        IntegratorHelper.getFormattedTableConfigForRequest({
          ...this.tablesConfigData,
          fields: this.insertedFields,
        }),
      ];

      const tableCompanyIdsData: TableConfigurations = this.$store.getters['integrator_config/getSpecificTableFromTablesList'](this.selectedTable);

      if (tableCompanyIdsData) {
        let formattedStoreTableData = tableCompanyIdsData.tablesByCompanies
          .map(IntegratorHelper.getFormattedTableConfigForRequest);

        if (this.isEditing) {
          formattedStoreTableData = formattedStoreTableData
            .filter((table) => table.company_id !== this.selectedDataToUpdate.companyId);
        }

        formattedIntegratorTablesRequest.push(...formattedStoreTableData);
      }

      this.saveTableOnFile({
        table_name: this.selectedTable as IntegratorTableNameEnum,
        table_configs: formattedIntegratorTablesRequest,
      });
    },
	},
});
