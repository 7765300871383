



































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import IntegratorInnCashCredentialsConfig from '@/views/integratorConfig/interfaces/forms/IntegratorInnCashCredentialsConfig';
import IntegratorInnCashCredentialsConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorInnCashCredentialsConfigRequest';
import { VForm } from '@/types/VForm';

export default defineComponent({
	name: 'IntegratorInnCashCredentialsConfigTab',
	data() {
		const inputRules: InputRulesHelper = new InputRulesHelper();
		const integratorRepository:
			IntegratorRepository = new IntegratorRepository();

		const innCashCredentialsConfigData: IntegratorInnCashCredentialsConfig = {
			url: '',
			email: '',
			password: '',
		};

		const loading: boolean = false;

		return {
			inputRules,
			integratorRepository,
			innCashCredentialsConfigData,
			loading,
		};
	},
	computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
  },
	created() {
		this.getInnCashCredentialsConfigData();
	},
	methods: {
		async getInnCashCredentialsConfigData(): Promise<void> {
			try {
				this.loading = true;

				const innCashCredentialsConfig = await this.integratorRepository
					.getInnCashCredentialsConfiguration(this.companyGroupId);

					this.innCashCredentialsConfigData.url = innCashCredentialsConfig.url;
					this.innCashCredentialsConfigData.email = innCashCredentialsConfig.email;
					this.innCashCredentialsConfigData.password = innCashCredentialsConfig.password;
			} catch (error) {
				this.$notification.error('Houve um problema ao requisitar os dados de configuração de credênciais do InnCash');
			} finally {
				this.loading = false;
			}
		},
		async handleClickSaveInnCashCredentialsConfig(): Promise<void> {
			try {
				this.$dialog.startLoading();

				const requestData = this.validateFormAndGetValuesRequest();

				if (!requestData) {
					return;
				}

				await this.integratorRepository
					.setInnCashCredentialsConfiguration(this.companyGroupId, requestData);

				this.$notification.success('Configuração de Credênciais gravada com sucesso.');
			} catch (error) {
				this.$notification.error('Houve um problema ao salvar o arquivo de configuração de credênciais do InnCash');
			} finally {
				this.$dialog.stopLoading();
			}
		},
		validateFormAndGetValuesRequest(): IntegratorInnCashCredentialsConfigRequest|null {
			const innCashCredentialsConfigForm = this.$refs
        .IntegratorInnCashCredentialsConfigForm as VForm;

			if (!innCashCredentialsConfigForm.validate()) {
				this.$notification.error('Há campos preenchidos incorretamente!');
				return null;
			}

			return {
				url: this.innCashCredentialsConfigData.url,
				email: this.innCashCredentialsConfigData.email,
				password: this.innCashCredentialsConfigData.password,
			};
		},
	},
});
