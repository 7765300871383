







































































































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import IntegratorRefreshAllManagementConfig from '@/views/integratorConfig/interfaces/forms/IntegratorRefreshAllManagementConfig';
import IntegratorRefreshAllManagementConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorRefreshAllManagementConfigRequest';
import { VForm } from '@/types/VForm';

export default defineComponent({
	name: 'IntegratorRefreshAllManagementConfigTab',
	data() {
		const inputRules: InputRulesHelper = new InputRulesHelper();
		const integratorRepository:
			IntegratorRepository = new IntegratorRepository();

		const refreshAllConfigData: IntegratorRefreshAllManagementConfig = {
      customerRefreshAll: false,
      customerNCCRefreshAll: false,
      providerRefreshAll: false,
      bankBalanceRefreshAll: false,
      accountPayableRefreshAll: false,
      accountReceivableRefreshAll: false,
      accountReceivableNCCRefreshAll: false,
      financialNatureRefreshAll: false,
      bankRefreshAll: false,
      costCenterRefreshAll: false,
      valueClassRefreshAll: false,
      currencyRefreshAll: false,
      accountingItemRefreshAll: false,
      bankTransactionRefreshAll: false,
		};

		const loading: boolean = false;

		return {
			inputRules,
			integratorRepository,
			refreshAllConfigData,
			loading,
		};
	},
	computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
  },
	created() {
		this.getRefreshAllManagementConfigData();
	},
	methods: {
		async getRefreshAllManagementConfigData(): Promise<void> {
			try {
				this.loading = true;

				const refreshAllManagement = await this.integratorRepository
					.getRefreshAllManagementConfiguration(this.companyGroupId);

				this.refreshAllConfigData = { ...refreshAllManagement };
			} catch (error) {
				this.$notification.error('Houve um problema ao requisitar os dados de configuração do Sinalizador de Recurso');
			} finally {
				this.loading = false;
			}
		},
		async handleClickSaveFeatureFlagConfig(): Promise<void> {
			try {
				this.$dialog.startLoading();

				const requestData = this.validateFormAndGetValuesRequest();

				if (!requestData) {
					return;
				}

				await this.integratorRepository
					.setRefreshAllManagementConfiguration(this.companyGroupId, requestData);

				this.$notification.success('Configuração de Atualização gravada com sucesso.');
			} catch (error) {
				this.$notification.error('Houve um problema ao salvar o arquivo de configuração do Sinalizador de Recurso');
			} finally {
				this.$dialog.stopLoading();
			}
		},
		validateFormAndGetValuesRequest(): IntegratorRefreshAllManagementConfigRequest|null {
			const refreshAllManagementConfigForm = this.$refs
        .IntegratorRefreshAllManagementConfigForm as VForm;

			if (!refreshAllManagementConfigForm.validate()) {
				this.$notification.error('Há campos preenchidos incorretamente!');
				return null;
			}

			return {
        customer_refresh_all: this.refreshAllConfigData.customerRefreshAll,
        customer_ncc_refresh_all: this.refreshAllConfigData.customerNCCRefreshAll,
        provider_refresh_all: this.refreshAllConfigData.providerRefreshAll,
        bank_balance_refresh_all: this.refreshAllConfigData.bankBalanceRefreshAll,
        account_payable_refresh_all: this.refreshAllConfigData.accountPayableRefreshAll,
        account_receivable_refresh_all: this.refreshAllConfigData.accountReceivableRefreshAll,
        account_receivable_ncc_refresh_all: this.refreshAllConfigData
          .accountReceivableNCCRefreshAll,
        financial_nature_refresh_all: this.refreshAllConfigData.financialNatureRefreshAll,
        bank_refresh_all: this.refreshAllConfigData.bankRefreshAll,
        cost_center_refresh_all: this.refreshAllConfigData.costCenterRefreshAll,
        value_class_refresh_all: this.refreshAllConfigData.valueClassRefreshAll,
        currency_refresh_all: this.refreshAllConfigData.currencyRefreshAll,
        accounting_item_refresh_all: this.refreshAllConfigData.accountingItemRefreshAll,
        bank_transaction_refresh_all: this.refreshAllConfigData.bankTransactionRefreshAll,

			};
		},
	},
});
