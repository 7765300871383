





























































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import IntegratorSensediaConfig from '@/views/integratorConfig/interfaces/forms/IntegratorSensediaConfig';
import IntegratorSensediaConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorSensediaConfigRequest';
import { VForm } from '@/types/VForm';

export default defineComponent({
	name: 'IntegratorSensediaConfigTab',
	data() {
		const inputRules: InputRulesHelper = new InputRulesHelper();
		const integratorRepository:
			IntegratorRepository = new IntegratorRepository();

		const sensediaConfigData: IntegratorSensediaConfig = {
			clientSecret: '',
			clientId: '',
			redirectUri: '',
			grantCodeUri: '',
			accessTokenUri: '',
			integrationUri: '',
		};

		const loading: boolean = false;

		return {
			inputRules,
			integratorRepository,
			sensediaConfigData,
			loading,
		};
	},
	computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
  },
	created() {
		this.getSensediaConfigData();
	},
	methods: {
		async getSensediaConfigData(): Promise<void> {
			try {
				this.loading = true;

				const sensediaConfig = await this.integratorRepository
					.getSensediaConfiguration(this.companyGroupId);

					this.sensediaConfigData.clientSecret = sensediaConfig.clientSecret;
					this.sensediaConfigData.clientId = sensediaConfig.clientId;
					this.sensediaConfigData.redirectUri = sensediaConfig.redirectUri;
					this.sensediaConfigData.grantCodeUri = sensediaConfig.grantCodeUri;
					this.sensediaConfigData.accessTokenUri = sensediaConfig.accessTokenUri;
					this.sensediaConfigData.integrationUri = sensediaConfig.integrationUri;
			} catch (error) {
				this.$notification.error('Houve um problema ao requisitar os dados de configuração do Sensedia');
			} finally {
				this.loading = false;
			}
		},
		async handleClickSaveSensediaConfig(): Promise<void> {
			try {
				this.$dialog.startLoading();

				const requestData = this.validateFormAndGetValuesRequest();

				if (!requestData) {
					return;
				}

				await this.integratorRepository
					.setSensediaConfiguration(this.companyGroupId, requestData);

				this.$notification.success('Configuração de Sensedia gravado com sucesso.');
			} catch (error) {
				this.$notification.error('Houve um problema ao salvar o arquivo de configuração do Sensedia');
			} finally {
				this.$dialog.stopLoading();
			}
		},
		validateFormAndGetValuesRequest(): IntegratorSensediaConfigRequest|null {
			const sensediaConfigForm = this.$refs.IntegratorSensediaConfigForm as VForm;

			if (!sensediaConfigForm.validate()) {
				this.$notification.error('Há campos preenchidos incorretamente!');
				return null;
			}

			return {
				client_secret: this.sensediaConfigData.clientSecret,
				client_id: this.sensediaConfigData.clientId,
				redirect_uri: this.sensediaConfigData.redirectUri,
				grant_code_uri: this.sensediaConfigData.grantCodeUri,
				access_token_uri: this.sensediaConfigData.accessTokenUri,
				integration_uri: this.sensediaConfigData.integrationUri,
			};
		},
	},
});
