






































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
  Ref,
} from 'vue-property-decorator';
import AccountActionsEnum from '@/repositories/parameters/AccountLogs/enums/AccountActionsEnum';
import boletoValidator from '@mrmgomes/boleto-utils';
import InputRules from '@/helpers/InputRulesHelper';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import AccountsLogRepository from '@/repositories/AccountsLogRepository';
import { formatErrorForNotification } from '@/utils/error';
import { VForm } from '@/types/VForm';

@Component
export default class PaymentRemittancesActionEditFGTS extends Vue {
  @Ref('EditFgtsForm') editFgtsForm!: VForm;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Watch('item', { immediate: true })
  changedOpen() {
    if (this.item) {
      this.document = this.item.account_payable_supplier_document;
      this.barcodeInput = this.item.barcode;
      this.barcodeInputChanged(this.item.barcode);
    }
  }

  @Emit()
  close(reload: boolean = false) {
    return reload;
  }

  readonly paymentRemittanceRepository: PaymentRemittanceRepository =
    new PaymentRemittanceRepository();
  readonly accountsLogRepository: AccountsLogRepository = new AccountsLogRepository();

  readonly inputRules: InputRules = new InputRules();

  digitableLine: string = '';
  barcodeInput: string = '';
  document: string = '';

  isBarcodeValidated: boolean = false;

  get group() {
    return this.$session.get('company_group_id');
  }

  get useNCC() {
    return this.item
      && this.item.type === 'NCC'
      && Boolean(this.$session.get('use_ncc'));
  }

  get documentMask(): string {
    let mask: string = '##################';

    if (!this.document) return mask;

    const value = this.justNumbers(this.document);

    if (value.length <= 11) {
      mask = '###.###.###-##';
    } else if (value.length >= 12) {
      mask = '##.###.###/####-##';
    }

    return mask;
  }

  digitableLineChanged(value: string): void {
    if (value) {
      this.barcodeInput = '';

      const response = boletoValidator.validarBoleto(value);

      this.isBarcodeValidated = response.sucesso;

      if (!response.sucesso) {
        this.$notification.warn(response.mensagem);
        return;
      }

      this.barcodeInput = response.codigoBarras;
    }
  }

  barcodeInputChanged(value: string): void {
    value = value.replace(/[^0-9]/g, '');

    if (value && value.length >= 44) {
      this.digitableLine = '';

      const response = boletoValidator.validarBoleto(value);

      this.isBarcodeValidated = response.sucesso;

      if (!response.sucesso) {
        this.$notification.warn(response.mensagem);
        return;
      }

      this.digitableLine = response.linhaDigitavel;
    }
  }

  requiredMinDocument = (value: string) => {
    const { length } = this.justNumbers(value);
    return (length === 11 || length === 14) || 'Valor inválido!';
  };

  justNumbers(value: string): string {
    return value.replace(/[^\d]/g, '');
  }

  reset(): void {
    this.isBarcodeValidated = false;
    this.digitableLine = '';
    this.barcodeInput = '';
    this.document = '';
  }

  save(): void {
    if (!this.editFgtsForm.validate()) {
      this.$notification.error('O campo de CPF/CNPJ está preenchido de forma incorreta.');
      return;
    }

    if (this.barcodeInput.length < 44) {
      this.$notification.error('O campo de código de barras está com menos de 44 caracteres.');
      return;
    }

    if (!this.isBarcodeValidated) {
      this.$notification.error('O campo de código de barras está inválido.');
      return;
    }

    this.handleUpdateFgtsFields();
  }

  async updateDocumentFromAccountTable(document: string): Promise<void> {
    await this.paymentRemittanceRepository.updateInternal(
      'updateImportTable',
      {
        identify_values: this.useNCC ? 'CR' : 'CP',
        id: this.item.id,
        field: this.useNCC ? 'e1_zcgc' : 'e2_zcgc',
        value: document,
      },
    );
  }

  async updateBarcodeFromAccountTable(): Promise<void> {
    await this.paymentRemittanceRepository.updateInternal(
      'updateImportTable',
      {
        identify_values: this.useNCC ? 'CR' : 'CP',
        id: this.item.id,
        field: this.useNCC ? 'e1_codbar' : 'e2_codbar',
        value: this.barcodeInput,
      },
    );
  }

  async updateDocumentAndBarcodeOnErp(document: string): Promise<void> {
    await this.paymentRemittanceRepository.updateERP(
      this.group,
      this.item.company_id,
      {
        id: this.item.id_customer,
        data: {
          document,
          barcode: this.barcodeInput,
          type: this.useNCC ? 'account_receivable' : 'account_payable',
        },
        event: 'barcode',
      },
    );
  }

  async handleUpdateFgtsFields(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const formattedDocument = this.justNumbers(this.document);

      await Promise.all([
        this.updateDocumentFromAccountTable(formattedDocument),
        this.updateBarcodeFromAccountTable(),
        this.updateDocumentAndBarcodeOnErp(formattedDocument),
      ]);

      await this.accountsLogRepository.createLog(
        this.group,
        {
          type: 'payable',
          company_id: this.item.company_id,
          action: AccountActionsEnum.CHANGE_PAYMENT_DATA,
          content: 'Alteração dos dados de pagamento de FGTS feito com sucesso.',
          id_customer: this.item.id_customer,
        },
      );

      this.$notification.success('O CPF/CNPJ foi atualizado com sucesso!');
      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }
}
