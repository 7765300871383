





















































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import CompanyRepository from '@/repositories/CompanyRepository';
import IntegratorTablesConfigTab from '@/views/integratorConfig/components/IntegratorTablesConfigTab.vue';
import IntegratorSensediaConfigTab from '@/views/integratorConfig/components/IntegratorSensediaConfigTab.vue';
import IntegratorFeatureFlagConfigTab from '@/views/integratorConfig/components/IntegratorFeatureFlagConfigTab.vue';
import IntegratorDatabaseConnectionConfigTab from '@/views/integratorConfig/components/IntegratorDatabaseConnectionConfigTab.vue';
import IntegratorInncashCredentialsConfigTab from '@/views/integratorConfig/components/IntegratorInnCashCredentialsConfigTab.vue';
import IntegratorBranchAndCompanyConfigTab from '@/views/integratorConfig/components/IntegratorBranchAndCompanyConfigTab.vue';
import IntegratorRefreshAllManagementConfigTab from '@/views/integratorConfig/components/IntegratorRefreshAllManagementConfigTab.vue';
import Company from '@/domain/models/Company';
import VMenuOptions from '@/types/VMenuOptions';

export default defineComponent({
  name: 'IntegratorConfig',
  components: {
    IntegratorTablesConfigTab,
    IntegratorSensediaConfigTab,
    IntegratorFeatureFlagConfigTab,
    IntegratorDatabaseConnectionConfigTab,
    IntegratorInncashCredentialsConfigTab,
    IntegratorBranchAndCompanyConfigTab,
    IntegratorRefreshAllManagementConfigTab,
  },
  data() {
    const actualTab: string = 'tables';

    const companyRepository: CompanyRepository = new CompanyRepository();

    const companies: Company[] = [];
    const tabs: VMenuOptions[] = [
      { value: 'tables', text: 'Tabelas' },
      { value: 'branch-and-company-config', text: 'Filial' },
      { value: 'company', text: 'Empresas' },
      { value: 'database', text: 'Banco de Dados' },
      { value: 'feature-flag', text: 'Sinalizador de recurso' },
      { value: 'inncash-credentials', text: 'Credênciais do InnCash' },
      { value: 'refresh-all-management', text: 'Regras de atualização dos campos' },
    ];

    return {
      actualTab,
      companyRepository,
      companies,
      tabs,
    };
  },
	computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
  },
  created() {
    this.getCompanies();
  },
  methods: {
    async getCompanies(): Promise<void> {
      try {
        const companies = await this.companyRepository
          .getCompaniesByLoggedUser(this.companyGroupId);

        this.companies = companies;
        this.$store.commit('integrator_config/setCompanies', companies);
      } catch (error) {
        this.$notification.error('Houve um problema para requisitar as empresas');
      }
    },
  },
});
