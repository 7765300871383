export default class RefreshAllManagementConfiguration {
	constructor(
        public customerRefreshAll: boolean,
        public customerNCCRefreshAll: boolean,
        public providerRefreshAll: boolean,
        public bankBalanceRefreshAll: boolean,
        public accountPayableRefreshAll: boolean,
        public accountReceivableRefreshAll: boolean,
        public accountReceivableNCCRefreshAll: boolean,
        public financialNatureRefreshAll: boolean,
        public bankRefreshAll: boolean,
        public costCenterRefreshAll: boolean,
        public valueClassRefreshAll: boolean,
        public currencyRefreshAll: boolean,
        public accountingItemRefreshAll: boolean,
        public bankTransactionRefreshAll: boolean,
	) { }

	static make(data: any): RefreshAllManagementConfiguration {
		return new RefreshAllManagementConfiguration(
            data.customer_refresh_all,
            data.customer_ncc_refresh_all,
            data.provider_refresh_all,
            data.bank_balance_refresh_all,
            data.account_payable_refresh_all,
            data.account_receivable_refresh_all,
            data.account_receivable_ncc_refresh_all,
            data.financial_nature_refresh_all,
            data.bank_refresh_all,
            data.cost_center_refresh_all,
            data.value_class_refresh_all,
            data.currency_refresh_all,
            data.accounting_item_refresh_all,
            data.bank_transaction_refresh_all,
		);
	}
}
