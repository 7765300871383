
























































































































































































































































































import { defineComponent } from '@vue/composition-api';

import AccountPayableRepository from '@/repositories/AccountPayableRepository';
import AccountReceivableRepository from '@/repositories/AccountReceivableRepository';
import AccountDetails from '@/domain/models/cashFlow/new/AccountDetails';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import CurrencyHelper from '@/helpers/CurrencyHelper';
import { formateDate } from '@/utils/date';
import PredictedFlowActionsEnum from '../../enums/PredictedFlowActionsEnum';
import IPredictedFlowGrouperList from '../../interfaces/groupers/IPredictedFlowGrouperList';

export default defineComponent({
  name: 'ShowAccountDetailsDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object as () => IPredictedFlowGrouperList,
    },
    totalsIndex: {
      required: false,
      type: Number,
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  computed: {
    alteredValueFormatted(): string {
      if (!this.details.coinId) {
        return '';
      }

      const currencyPrefix = CurrencyHelper.getCurrencyPrefixBasedOnId(this.details.coinId);

      const formattedValue = CurrencyHelper
        .toCurrency(this.details.alteredValue, { showCurrencySymbol: false });

      return `${currencyPrefix} ${formattedValue}`;
    },
    getCompleteNatureText(): string {
      if (!this.details.natureCode) {
        return '';
      }

      return `${this.details.natureCode} - ${this.details.natureDescription}`;
    },
  },
  data() {
    const details = {} as AccountDetails;
    const accountPayableRepository = new AccountPayableRepository();
    const accountReceivableRepository = new AccountReceivableRepository();
    const isOpen: boolean = this.value;
    const currencyHelper = CurrencyHelper;
    const formatDate = formateDate;
    const tabs: any = null;
    const documentFormattation = DocumentFormattation;
    const loading = false;

    return {
      details,
      loading,
      accountPayableRepository,
      accountReceivableRepository,
      isOpen,
      formatDate,
      documentFormattation,
      currencyHelper,
      tabs,
    };
  },
  created() {
    if (this.register) {
      this.getDetails(this.register);
    }
  },
  methods: {
    async getDetails(register: IPredictedFlowGrouperList) {
      try {
        this.loading = true;

        const idCustomer = register.itemData?.idCustomer ?? '';
        const companyId = register.itemData?.companyId ?? 0;
        const date = register.totals[this.totalsIndex!].date ?? '';

        if (register.grouperType === 'accounts_payable') {
          this.details = await this.accountPayableRepository.findtoDetails(
            idCustomer,
            this.companyGroupId,
            companyId,
            date,
          );
        } else {
          this.details = await this.accountReceivableRepository.find(
            idCustomer,
            this.companyGroupId,
            companyId,
            date,
          );
        }
      } catch (error) {
        this.$notification.error('Houve um problema ao detalhar o título.');
      } finally {
        this.loading = false;
      }
    },

    formatDocument(value: string | undefined): string | undefined {
      return value;
    },

    handleFormatDate(date: string|null|undefined) {
      if (!date) {
        return '';
      }

      return this.formatDate(date);
    },

    close(): void {
      this.$emit('close', PredictedFlowActionsEnum.show_details);
    },
  },
});
