import AccountDetails from '@/domain/models/cashFlow/new/AccountDetails';
import Repository from './base/Repository';

export default class AccountPayableRepository extends Repository {
  public async find(
    id: string,
    companyGroupId: number,
    companyId: number,
  ): Promise<AccountDetails> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-payable/${id}`,
      {
        headers: { 'company-id': JSON.stringify(companyId) },
      },
    );

    return AccountDetails.make(response.data);
  }

  public async findtoDetails(
    id: string,
    companyGroupId: number,
    companyId: number,
    date: string,
  ): Promise<AccountDetails> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-payable/details/${id}`,
      {
        params: { date },
        headers: { 'company-id': JSON.stringify(companyId) },
      },
    );

    return AccountDetails.make(response.data);
  }

  public async getRiskTakenOptions(companyGroupId: number): Promise<string[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/accounts-payable/fields/risk-taken`);

    return response.data.map(({ value }: { value: string }) => value);
  }
}
