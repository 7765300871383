import ProcesseEventEnum from '@/components/navigation/toolbar/enums/ProcesseEventEnum';

export default class Notification {
  constructor(
    public processName: ProcesseEventEnum,
    public id: number,
    public data: string,
    public messageText: string,
    public wasViewed: boolean,
    public dateTime: string,
  ) {}

  static make(data: any): Notification {
    return new Notification(
      data.process_name,
      data.id,
      data.data,
      data.message_text,
      Boolean(data.was_viewed),
      data.date_time,
    );
  }
}
