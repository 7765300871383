import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":false,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"notification-badge",attrs:{"content":_vm.notificationsNotViewed,"value":_vm.notificationsNotViewed,"overlap":"","bordered":"","bottom":""}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.notifications.length < 1},on:{"click":_vm.handleViewNotifications}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-bell")])],1)],1)]}}])},[_c(VCard,[_c(VList,[_vm._l((_vm.notifications),function(notification,index){return [_c(VListItem,{key:notification.id},[_c(VListItemContent,[_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(notification.messageText)}}),_c(VListItemSubtitle,[_c('span',{staticClass:"mr-1 text-decoration-underline",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.downloadFileByURL(notification.id)}}},[_vm._v(" Clique aqui para baixar o arquivo. ")])])],1),_c(VListItemAction,[_c(VListItemActionText,[_vm._v(" "+_vm._s(_vm.formatDate(notification.dateTime))+" ")])],1)],1),(index < _vm.notifications.length - 1)?_c(VDivider,{key:("divider-" + index)}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }