import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-7",attrs:{"fluid":""}},[_c(VForm,{ref:"IntegratorFeatureFlagConfigForm"},[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VSwitch,{attrs:{"label":"Usar o InnCash","filled":"","height":"56px","loading":_vm.loading},model:{value:(_vm.featureFlagConfigData.useInnCash),callback:function ($$v) {_vm.$set(_vm.featureFlagConfigData, "useInnCash", $$v)},expression:"featureFlagConfigData.useInnCash"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VSwitch,{attrs:{"label":"Usar o Sensedia","filled":"","height":"56px","loading":_vm.loading},model:{value:(_vm.featureFlagConfigData.useSensedia),callback:function ($$v) {_vm.$set(_vm.featureFlagConfigData, "useSensedia", $$v)},expression:"featureFlagConfigData.useSensedia"}})],1)],1)],1),_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"button-width",attrs:{"color":"success","large":"","loading":_vm.loading},on:{"click":_vm.handleClickSaveFeatureFlagConfig}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }