export default class AccountDetails {
    constructor(
        public companyName: string,
        public corporateName: string,
        public tradeName: string,
        public prefix: string,
        public number: string,
        public type: string,
        public document: string,
        public value: number,
        public parcel: string,
        public increase: number,
        public decrease: number,
        public total: number,
        public balance: number,
        public nature: string,
        public emissionDate: string,
        public dueDate: string,
        public flowDate: string,
        public bankCode: string,
        public form: string,
        public interactions: Array<any>,
        public fantasyName: string,
        public alteredValue: string,
        public natureCode: string,
        public natureDescription: string,
        public realDueDate: string,
        public observation: string,
        public bordero: string,
        public coinId: number|string|undefined,
    ) { }

    static make(data: any): AccountDetails {
      return new AccountDetails(
        data.company_name,
        data.corporate_name,
        data.trade_name,
        data.prefix,
        data.number,
        data.type,
        data.document,
        data.value,
        data.parcel,
        data.increase,
        data.decrease,
        data.total,
        data.balance,
        data.nature,
        data.emission_date,
        data.due_date,
        data.flow_date,
        data.bank_code,
        data.form,
        data.interactions,
        data.fantasy_name,
        data.altered_value,
        data.nature_code,
        data.nature_description ?? '',
        data.real_due_date,
        data.observation,
        data.bordero,
        data.coin_id,
      );
    }
  }
