










































import { defineComponent } from '@vue/composition-api';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import TableConfigurations from '@/domain/models/integrator/TableConfigurations';
import DialogHelper from '@/helpers/DialogHelper';
import IntegratorTablesWithFieldsConfigRequest from '../../interfaces/requests/IntegratorTablesWithFieldsConfigRequest';
import IntegratorHelper from '../../helpers/IntegratorHelper';
import IRemoveIntegratorIndexes from '../../interfaces/IRemoveIntegratorIndexes';

export default defineComponent({
  name: 'RemoveIntegratorTableByCompanyDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    removeTableIndexes: {
      required: true,
      type: Object as () => IRemoveIntegratorIndexes,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    tablesConfigurations: {
      required: true,
      type: Array as () => TableConfigurations[],
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const integratorRepository: IntegratorRepository = new IntegratorRepository();

    const loading = false;
    const isOpen: boolean = this.value;

    return {
      integratorRepository,
      loading,
      isOpen,
    };
  },
  methods: {
    async saveTableOnFile(tableData: IntegratorTablesWithFieldsConfigRequest): Promise<void> {
      try {
        this.$dialog.startLoading();

        await this.integratorRepository.setTableConfiguration(this.companyGroupId, tableData);

        this.$notification.success('Tabela removida com Sucesso!');
      } catch (error) {
        this.$notification.error('Houve um problema ao enviar os dados do arquivo de tabela.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    async handleDelete(): Promise<void> {
      const { tablesFieldIndex, tablesIndex } = this.removeTableIndexes;
      const tableConfigurationsCopy = JSON.parse(JSON.stringify(this.tablesConfigurations));

      tableConfigurationsCopy[tablesIndex].tablesByCompanies.splice(tablesFieldIndex, 1);

      await this.saveTableOnFile({
        table_name: tableConfigurationsCopy[tablesIndex].tableName,
        table_configs: tableConfigurationsCopy[tablesIndex].tablesByCompanies
          .map(IntegratorHelper.getFormattedTableConfigForRequest),
      });

      this.$emit('on-remove', tableConfigurationsCopy);
    },
    close(): void {
      this.$emit('close');
    },
  },
});
