













































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import IntegratorFeatureFlagConfig from '@/views/integratorConfig/interfaces/forms/IntegratorFeatureFlagConfig';
import IntegratorFeatureFlagConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorFeatureFlagConfigRequest';
import { VForm } from '@/types/VForm';

export default defineComponent({
	name: 'IntegratorFeatureFlagConfigTab',
	data() {
		const inputRules: InputRulesHelper = new InputRulesHelper();
		const integratorRepository:
			IntegratorRepository = new IntegratorRepository();

		const featureFlagConfigData: IntegratorFeatureFlagConfig = {
			useInnCash: false,
			useSensedia: false,
		};

		const loading: boolean = false;

		return {
			inputRules,
			integratorRepository,
			featureFlagConfigData,
			loading,
		};
	},
	computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
  },
	created() {
		this.getfeatureFlagConfigData();
	},
	methods: {
		async getfeatureFlagConfigData(): Promise<void> {
			try {
				this.loading = true;

				const featureFlagConfig = await this.integratorRepository
					.getFeatureFlagConfiguration(this.companyGroupId);

					this.featureFlagConfigData.useInnCash = featureFlagConfig.useInnCash;
					this.featureFlagConfigData.useSensedia = featureFlagConfig.useSensedia;
			} catch (error) {
				this.$notification.error('Houve um problema ao requisitar os dados de configuração do Sinalizador de Recurso');
			} finally {
				this.loading = false;
			}
		},
		async handleClickSaveFeatureFlagConfig(): Promise<void> {
			try {
				this.$dialog.startLoading();

				const requestData = this.validateFormAndGetValuesRequest();

				if (!requestData) {
					return;
				}

				await this.integratorRepository
					.setFeatureFlagConfiguration(this.companyGroupId, requestData);

				this.$notification.success('Configuração de Sinalizador de recurso gravada com sucesso.');
			} catch (error) {
				this.$notification.error('Houve um problema ao salvar o arquivo de configuração do Sinalizador de Recurso');
			} finally {
				this.$dialog.stopLoading();
			}
		},
		validateFormAndGetValuesRequest(): IntegratorFeatureFlagConfigRequest|null {
			const featureFlagConfigForm = this.$refs.IntegratorFeatureFlagConfigForm as VForm;

			if (!featureFlagConfigForm.validate()) {
				this.$notification.error('Há campos preenchidos incorretamente!');
				return null;
			}

			return {
				use_inncash: this.featureFlagConfigData.useInnCash,
				use_sensedia: this.featureFlagConfigData.useSensedia,
			};
		},
	},
});
