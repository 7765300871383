






























































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IntegratorRepository from '@/repositories/IntegratorRepository';
import IntegratorBranchAndCompanyConfig from '@/views/integratorConfig/interfaces/forms/IntegratorBranchAndCompanyConfig';
import IntegratorBranchAndCompanyConfigRequest from '@/views/integratorConfig/interfaces/requests/IntegratorBranchAndCompanyConfigRequest';
import CompanyAndBranchConfiguration from '@/domain/models/integrator/CompanyAndBranchConfiguration';
import Company from '@/domain/models/Company';
import { VForm } from '@/types/VForm';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';

export default defineComponent({
	name: 'IntegratorBranchAndCompanyConfigTab',
	data() {
		const inputRules: InputRulesHelper = new InputRulesHelper();
		const integratorRepository:
			IntegratorRepository = new IntegratorRepository();

		const companyAndBranchFormData: IntegratorBranchAndCompanyConfig = {
			companyId: NaN,
			companyCode: '',
			branchCode: '',
		};

    const companyCodeAndBranchList: CompanyAndBranchConfiguration[] = [];

		const loading: boolean = false;
    const actualRegisterIndex: number|undefined = undefined;

		return {
			inputRules,
			integratorRepository,
      companyAndBranchFormData,
      companyCodeAndBranchList,
			loading,
      actualRegisterIndex: actualRegisterIndex as number|undefined,
		};
	},
  props: {
    companies: {
      required: true,
      type: Array as () => Company[],
    },
  },
	computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
    companyOptions(): ISelectOptions<number>[] {
      const companyIdsExistentOnList = this.companyCodeAndBranchList
        .map(({ companyId }) => companyId);

      return this.companies
        .filter((company) => (
          !companyIdsExistentOnList.includes(company.id)
          || (this.companyAndBranchFormData.companyId === company.id)
        ))
        .map((company) => ({ value: company.id, text: company.fantasyName }));
    },
  },
	created() {
		this.getIntegratorCompanyAndBranchConfigData();
	},
	methods: {
		async getIntegratorCompanyAndBranchConfigData(): Promise<void> {
			try {
				this.loading = true;

				const companyCodeAndBranchList = await this.integratorRepository
					.getCompanyAndBranchConfiguration(this.companyGroupId);

          this.companyCodeAndBranchList = companyCodeAndBranchList;
			} catch (error) {
				this.$notification.error('Houve um problema ao requisitar os dados de configuração do Sinalizador de Recurso');
			} finally {
				this.loading = false;
			}
		},
    getCompanyNameById(companyId: number): string {
      const company = this.companies.find((company) => company.id === companyId);

      if (!company) {
        return '';
      }

      return company.fantasyName;
    },
    emptyFields(): void {
      this.companyAndBranchFormData.branchCode = '';
      this.companyAndBranchFormData.companyCode = '';
      this.companyAndBranchFormData.companyId = NaN;

      this.actualRegisterIndex = undefined;

      const branchAndCompanyConfigForm = this.$refs.IntegratorBranchAndCompanyConfigForm as VForm;

      branchAndCompanyConfigForm.resetValidation();
    },
		async handleClickSave(): Promise<void> {
			try {
				this.$dialog.startLoading();

				const requestData = this.validateFormAndGetValuesRequest();

				if (!requestData) {
					return;
				}

				await this.integratorRepository
					.setBranchAndCompanyConfigurationList(this.companyGroupId, requestData);

        this.$notification.success('Configuração de Empresa/Filial gravada com sucesso.');
			} catch (error) {
				this.$notification.error('Houve um problema ao salvar o arquivo de configuração do Sinalizador de Recurso');
			} finally {
				this.$dialog.stopLoading();
			}
		},
    async handleRemove(index: number): Promise<void> {
      try {
        this.$dialog.startLoading();

        this.companyCodeAndBranchList.splice(index, 1);

        const formattedCompanyCodeAndBranch = this.companyCodeAndBranchList.map((data) => ({
          branch_code: data.branchCode,
          company_code: data.companyCode,
          company_id: data.companyId,
        }));

        await this.integratorRepository.setBranchAndCompanyConfigurationList(
          this.companyGroupId,
          formattedCompanyCodeAndBranch,
        );

        this.$notification.success('Configuração de Empresa/Filial removida com sucesso.');
      } catch (error) {
        this.$notification.error('Houve um problema ao remover o registro');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    handleEdit(index: number, data: CompanyAndBranchConfiguration): void {
      this.companyAndBranchFormData.branchCode = data.branchCode;
      this.companyAndBranchFormData.companyCode = data.companyCode;
      this.companyAndBranchFormData.companyId = data.companyId;
      this.actualRegisterIndex = index;

      this.companyCodeAndBranchList.splice(index, 1);
    },
    handleAddBranchWithCompany(): void {
			const branchAndCompanyConfigForm = this.$refs.IntegratorBranchAndCompanyConfigForm as VForm;

			if (!branchAndCompanyConfigForm.validate()) {
				this.$notification.error('Há campos preenchidos incorretamente!');
				return;
			}

      this.companyCodeAndBranchList.push({ ...this.companyAndBranchFormData });

      this.emptyFields();
    },
		validateFormAndGetValuesRequest(): IntegratorBranchAndCompanyConfigRequest[]|null {
      return this.companyCodeAndBranchList.map((data) => ({
        branch_code: data.branchCode,
        company_code: data.companyCode,
        company_id: data.companyId,
      }));
		},
	},
});
