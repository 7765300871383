import CompanyGroupConfiguration from '@/domain/models/companyGroupConfiguration/CompanyGroupConfiguration';
import Repository from './base/Repository';

export default class CompanyGroupConfigurationRepository extends Repository {
  async getCompanyGroupConfigurationByCompanyGroupId(
    companyGroupId: number,
  ) : Promise<CompanyGroupConfiguration> {
    const { data: response } = await this.datasource.get(`/company-group-configuration/${companyGroupId}`);

    return CompanyGroupConfiguration.make(response.data);
  }
}
