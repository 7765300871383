<template>
  <div>
    <div id="params" class="m-portlet m-portlet--mobile">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 id="teste" class="m-portlet__head-text">Parâmetros</h3>
          </div>
        </div>
        <div class="m-portlet__head-tools">
          <ul class="m-portlet__nav">
            <li class="m-portlet__nav-item">
              <div class="dropdown">
                
                <div
                  id="dropdownViews"
                  class="dropdown-menu dropdown"
                  aria-labelledby="dropdownMenuButton"
                  style="
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  "
                >
                  <a
                    href="#"
                    class="dropdown-item loadView"
                    v-on:click="getView(view.id)"
                    v-bind:data-id="index"
                    v-for="(view, index) in views"
                    :key="index"
                    >{{ view.description }}</a
                  >
                </div>
              </div>
            </li>
            <li class="m-portlet__nav-item">
              <button id="btn-generate" type="button" class="btn m-btn--air btn-success">
                Gerar Fluxo
              </button>
            </li>
            <li class="m-portlet__nav-item">
              <v-btn
                icon
                class="d-flex ml-auto mt-1"
                depressed
                @click="toggleParams()"
              >
                <v-icon
                  x-large
                  class="text--disabled chevron"
                  :class="!paramsVisibility ? 'toggle-chevron' : ''"
                >
                  mdi-chevron-up
                </v-icon>
              </v-btn>
            </li>
          </ul>
        </div>
      </div>
      <v-slide-y-transition>
      <div class="m-portlet__body" v-show="paramsVisibility">
        <!--begin: Search Form -->
        <div
          class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
        >
          <div class="row align-items-center">
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <div class="form-group">
                <label>Data Inicial</label>
                <div class="input-group date" id="dateInitial">
                  <input
                    type="text"
                    class="form-control m-input text-center"
                    readonly=""
                    placeholder="Selecione a data"
                  />
                  <span class="input-group-addon">
                    <i class="la la-calendar-check-o"></i>
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <div class="form-group">
                <label>Data Final</label>
                <div class="input-group date" id="dateFinish">
                  <input
                    type="text"
                    class="form-control m-input text-center"
                    readonly=""
                    placeholder="Selecione a data"
                  />
                  <span class="input-group-addon">
                    <i class="la la-calendar-check-o"></i>
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12" xs="12">
              <div class="form-group">
                <label>Empresa</label>
                <select
                  class="form-control select2 hide"
                  field-name="Empresa"
                  id="company_id"
                  name="company_id[]"
                  style="width: 100%"
                  multiple="multiple"
                >
                  <option
                    v-for="company in companys"
                    :value="company.id"
                    :key="company.id"
                  >
                    {{ company.name }}
                  </option>
                </select>
              </div>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12" xs="12">
              <div class="form-group">
                <label>Tipo Relatório</label>
                <select
                  class="form-control select2 hide"
                  field-name="Tipo relatório"
                  id="report_type"
                  name="report_type"
                  style="width: 100%"
                >
                  <option value="A">Analítico</option>
                  <option value="S">Sintético</option>
                </select>
              </div>
            </v-col>
             <!-- <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <div class="form-group">
              <input name="nivel" id="nivel" value="four" type="hidden"/>
             
                <label>Qtd. Níveis</label>
                <select
                  class="form-control select2 hide"
                  field-name="Quantidade de níveis"
                  id="nivel"
                  name="nivel"
                  style="width: 100%"
                  disabled
                >
                  <option value="one">Nível 1</option>
                  <option value="two">Nível 2</option>
                  <option value="three">Nível 3</option>
                  <option value="four">Nível 4</option>
                </select>
              </div>
            </v-col> -->
            <v-col cols="12" lg="4" md="4" sm="12" xs="12">
              <div class="form-group">
                <label>Mostrar</label>
                <select
                  class="form-control select2 hide"
                  field-name="Mostrar"
                  id="approvalFilter"
                  name="approvalFilter"
                  style="width: 100%"
                >
                  <option value="T">Todos</option>
                  <option value="S">Liberados</option>
                  <option value="N">Não Liberados</option>
                </select>
              </div>
            </v-col>
            
            <v-col cols="12" lg="4" md="4" sm="12" xs="12">
              <div class="form-group">
                <label>Considera Saldos Bancários</label>
                <select
                  class="form-control select2 hide"
                  field-name="Considera Saldos Bancários"
                  id="saldo_bancario"
                  name="saldo_bancario"
                  style="width: 100%"
                >
                  <option value="erp">ERP</option>
                  <option value="file">Arquivo Banco</option>
                </select>
              </div>
            </v-col>
          </div>
        </div>
        <!--end: Search Form -->
      </div>
      </v-slide-y-transition>
    </div>

    <view-information ref="ViewInformation"></view-information>

    <update-information ref="UpdateInformation"></update-information>

    <generate-cnab ref="GenerateCnab"></generate-cnab>
    
    <!--begin::Modal-->
    <div
      class="modal fade"
      id="m_saveView"
      tabindex="-1"
      role="dialog"
      aria-labelledby="m_viewInformation"
      aria-hidden="false"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title_data_save_view">Salvar Visão</h5>
            <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="la la-remove"></span>
                        </button>-->
          </div>
          <form class="m-form m-form--fit m-form--label-align-right">
            <div id="data_view_information" class="modal-body">
              <!-- Nome -->
              <div class="col-md-12">
                <div class="form-group">
                  <label>Descrição</label>
                  <input
                    name="description"
                    v-model="view.description"
                    placeholder="Descrição de identificação"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn m-btn--air btn-success"
                v-on:click="saveView"
              >
                Salvar
              </button>
              <button
                type="button"
                class="btn m-btn--air btn-primary"
                id="btnModalClose"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--begin::Modal-->
    <div
      class="modal fade"
      id="m_printDate"
      tabindex="-1"
      role="dialog"
      aria-labelledby="m_viewPrint"
      aria-hidden="false"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title_data_save_view">
              Impressão do Fluxo de Caixa
            </h5>
            <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="la la-remove"></span>
                        </button>-->
          </div>
          <div id="data_view_information" class="modal-body">
            <div id="dates-print"></div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn m-btn--air btn-success"
              id="btnModalPrint"
              data-dismiss="modal"
            >
              Imprimir
            </button>
            <button
              type="button"
              class="btn m-btn--air btn-primary"
              id="btnModalClose"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end::Modal-->
  </div>
</template>

<script>
import _ from "lodash";
import {API_BASE} from '@config/api';

import ViewInformation from "../../components/view-information.vue";
import UpdateInformation from "../../components/update-information.vue";
import GenerateCnab from "../../components/generate-cnab.vue";
import HideParamnsSwitch from '../../../src/components/fields/HideParamnsSwitch.vue';
import { validateImporting, validatePaymentRemittance, validateBillingRemittance, ValidationError } from "@/utils/processes_validation.js";

export default {
  components: {
    "view-information": ViewInformation,
    "update-information": UpdateInformation,
    "generate-cnab": GenerateCnab,
    "hide-paramns-switch": HideParamnsSwitch,
  },
  data() {
    return {
      paramsVisibility: true,
      showButtonImport: false,
      showTicketModal: false,
      ticketModalData: null,
      companys: [],
      banks: [],
      cnabRemittancesConfig: [],
      dataKeys: [],
      dataIds: [],
      dataDescriptions: [],
      totalSelected: 0,
      countSelected: 0,
      totalSaldoDiff: 0,
      typeCnab: "",
      configs: {
        filterTerm: "",
        filterStatus: "",
        totalDays: 0,
        totalActiveDays: 0,
        totalHiddenDays: 0,
        firstDateActive: "",
        lastDateActive: "",
        leftHiddenDates: [],
        activeDates: [],
        rightHiddenDates: [],
        hiddenDates: [],
      },
      permissions: [],
      view: {
        filters: "",
        permissions: [],
        data: [],
        id: "",
        user_id: "",
        company_group_id: "",
        description: "",
      },
      views: [],
      roles: [],
      companyGroup: {},
      companyGroupImage: this.$session.get("company_group_image"),
      user_include_simulation:
        this.$session.get("user_access-include_simulation") == "1" ? true : false,
    };
  },

  directives: {
    select: {
      twoWay: true,
      bind: function () {
        $(this)
          .select2()
          .on(
            "select2:select",
            function (e) {
              this.set($(this).val());
            }.bind(this)
          );
      },
      update: function (nv, ov) {
        $(this).trigger("change");
      },
    },
  },

  mounted: function () {
    this.loadCompanys();
    this.loadMethod();
    this.loadViews();
    this.loadRoles();
    this.loadParameter();
  },

  methods: {
    toggleParams() {
        this.paramsVisibility = !this.paramsVisibility;
    },

    hiddenParams() {
        this.paramsVisibility = false;
    },
    loadCompanys: function () {
      blockPage("Carregando os dados...");
      this.$http
        .get(API_BASE + "company/listAllActv", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          function (result) {
            this.companys = result.body.data;
            unblockPage();
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        )
        .bind(this);
    },

    loadParameter: function () {
      var self = this;

      this.$http
        .get(API_BASE + "fieldparameter/getDataDashboard", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          function (result) {
            $("#dateInitial > input").val(result.body.data.date_initial);
            $("#dateInitial > input").datepicker({
              autoclose: true,
              format: "dd/mm/yyyy",
              todayHighlight: !0,
              orientation: "bottom left",
              defaultDate: result.body.data.date_initial,
              templates: {
                leftArrow: '<i class="la la-angle-left"></i>',
                rightArrow: '<i class="la la-angle-right"></i>',
              },
            });
            $("#dateFinish > input").val(result.body.data.date_finish);
            $("#dateFinish > input").datepicker({
              autoclose: true,
              format: "dd/mm/yyyy",
              todayHighlight: !0,
              orientation: "bottom left",
              defaultDate: result.body.data.date_finish,
              templates: {
                leftArrow: '<i class="la la-angle-left"></i>',
                rightArrow: '<i class="la la-angle-right"></i>',
              },
            });

            $("#approvalFilter").val(result.body.data.approval_filter || "T");
            $("#approvalFilter").trigger("change");

            $("#report_type").val(result.body.data.report_type);
            $("#report_type").trigger("change");

            $("#nivel").val(result.body.data.nivel);
            $("#nivel").trigger("change");

            $("#company_id").val(result.body.data.company_id);
            $("#company_id").trigger("change");

            $("#saldo_bancario").val(result.body.data.bank_balance);
            $("#saldo_bancario").trigger("change");
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        )
        .bind(this);
    },

    initView: function (data) {
      this.view = {
        filters: "",
        permissions: [],
        data: [],
        id: false,
        user_id: "",
        company_group_id: "",
        description: "",
      };
    },

    loadViews: function () {
      var self = this;
      this.$http
        .get(API_BASE + "view/list", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          function (result) {
            if (!result.error) {
              $.each(result.body.data, function (value, key) {
                self.addView(this);
              });
            }
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
          }
        );
    },

    loadRoles: function () {
      var self = this;
      this.$http
        .get(API_BASE + "usergroup/listAll", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          function (result) {
            if (!result.error) {
              $.each(result.body.data, function () {
                self.roles.push(this);
              });
            }
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
          }
        );
    },

    addView: function (view) {
      var self = this;
      self.views.push(view);
    },

    removeView: function (view) {
      var self = this;
      self.views = self.views.filter(function (obj) {
        return obj.id != view.id;
      });
      $("#fluxo").remove();
    },

    setView: function (view) {
      var self = this;
      var view = self.objetcToJson(view);
      self.view = view.view;
      self.loadViewData(view);
      self.loadFilters(view.data.filters);
      self.view.permissions = [{ id: "3", description: "Novo" }];
    },

    getFiltersForm: function () {
      var companys = "ALL";
      if ($("#company_id").val().length > 0) {
        companys = $("#company_id").val();
      }

      return {
        dateInitial: $("#dateInitial > input").val(),
        dateFinish: $("#dateFinish > input").val(),
        company_id: companys,
        report_type: $("#report_type").val(),
        approval_filter: $("#approvalFilter").val(),
      };
    },

    saveView: function () {
      var self = this;
      if ($.trim(self.view.description) == "") {
        showNotification("Atenção", "A descrição deve ser informada.", "warning");
        return false;
      }

      self.view.permissions = ['ALL'];

      if ($.trim(self.view.permissions) == "") {
        showNotification("Atenção", "As permissões devem ser informadas.", "warning");
        return false;
      }

      self.view.filters = self.getFiltersForm();
      $("#m_saveView").modal("hide");
      blockPage("Carregando os dados...");
      $.ajax({
        method: "POST",
        url: API_BASE + "view/save",
        headers: {
          token: self.$session.get("token"),
        },
        data: {
          filters: self.objetcToJson(self.view).filters,
          permissions: self.objetcToJson(self.view).permissions || [],
          description: self.view.description,
        },
        //data: self.view
      })
        .done(function (result) {
          unblockPage();
          if (result.error == false) {
            if (result.type == "created") {
              self.addView(result.data);
              self.getView(result.data.id);
              $("#m_saveView").modal("hide");
            }
          } else {
            showNotification("Atenção", result.error, "danger");
            return false;
          }
        })
        .fail(function (error) {
          unblockPage();
          showNotification(
            "Atenção",
            "Oops! Ocorreu um erro ao salvar a visão.",
            "danger"
          );
          console.log(error);
        });
    },

    getView: function (id) {
      var self = this;
      blockPage("Carregando os dados...");

      $.ajax({
        method: "GET",
        url: API_BASE + "view/get/" + id,
        headers: {
          token: self.$session.get("token"),
        },
      })
        .done(function (result) {
          if (result.error == false) {
            self.setView(result);
            unblockPage();
          } else {
            showNotification("Atenção", result.error, "danger");
            return false;
          }
        })
        .fail(function (error) {
          unblockPage();
          showNotification(
            "Atenção",
            "Oops! Ocorreu um erro ao salvar a visão.",
            "danger"
          );
          console.log(error);
        });
    },

    deleteView: function () {
      var self = this;
      var view = self.view;
      $.ajax({
        method: "POST",
        url: API_BASE + "view/delete",
        headers: {
          token: self.$session.get("token"),
        },
        data: { id: view.id },
      })
        .done(function (result) {
          if (result.error == false) {
            self.removeView(view);
            $("#fluxo").remove();
          } else {
            showNotification("Atenção", result.error, "danger");
            return false;
          }
        })
        .fail(function (error) {
          unblockPage();
          showNotification(
            "Atenção",
            "Oops! Ocorreu um erro ao excluir a visão.",
            "danger"
          );
          btn
            .removeClass("m-loader m-loader--right m-loader--light")
            .attr("disabled", false);
          console.log(error);
        });
    },

    loadFilters: function (filters) {
      $.each(filters, function (key) {
        $("#" + key + " > input").val(this);
      });
    },

    objetcToJson: function (data) {
      return JSON.parse(JSON.stringify(data));
    },

    loadMethod: function () {
      var self = this;

      //pega os dados de usuario e define na variavel do VUE
      self.$http
        .get(
          API_BASE +
            "companygroup/listOne/" +
            self.$session.get("company_group_id"),
          { headers: { token: self.$session.get("token") } }
        )
        .then((result) => {
          self.companyGroup = result.body.data[0];
        });
      numeral.language("pt-br");

      $(".select2").select2({
        placeholder: "Selecione as empresas ou deixe em branco para todas",
      });

      $(".select2Permissions")
        .select2({
          placeholder: "Selecione os tipos de usuário que poderão acessar",
        })
        .on("change", function () {
          self.view.permissions = $(this).val();
        });

      $("#dateInitial, #dateFinish").datepicker({
        autoclose: true,
        format: "dd/mm/yyyy",
        todayHighlight: !0,
        orientation: "bottom left",
        templates: {
          leftArrow: '<i class="la la-angle-left"></i>',
          rightArrow: '<i class="la la-angle-right"></i>',
        },
      });

      $(document).off("mouseover", ".showOptions");
      $(document).on("mouseover", ".showOptions", function () {
        var value = $(this)
          .find("i.fa.fa-check")
          .closest("fields_to_edit")
          .find("span")
          .text();
        var id_field = $(this)
          .find("i.fa.fa-check")
          .closest("fields_to_edit")
          .data("id_field");
        var identifyValue = $(this)
          .find("i.fa.fa-check")
          .closest("fields_to_edit")
          .data("identify-value");
        $(this).find("i.fa.fa-bars").css("display", "block");
        if (validateUserAccess(self, identifyValue, "approve")) {
          $(this).find("i.fa.fa-check").css("display", "block");
        }
      });

      $(document).off("mouseout", ".showOptions");
      $(document).on("mouseout", ".showOptions", function () {
        var identifyValue = $(this)
          .find("i.fa.fa-check")
          .closest("fields_to_edit")
          .data("identify-value");
        $(this).find("i.fa.fa-bars").css("display", "none");
        if (
          $(this).find("i.fa.fa-check").closest("fields_to_edit").attr("check") == "N"
        ) {
          if (validateUserAccess(self, identifyValue, "approve")) {
            $(this).find("i.fa.fa-check").css("display", "none");
          }
        }
      });

      //$(document).off('click', '.loadView');
      $(document).on("click", ".loadView", function (e) {
        var btn = $(this)[0];
        e.preventDefault();
        //self.setView($(this).attr('data-key'));
      });

      $(document).off("click", "#printFluxo");
      $(document).on("click", "#printFluxo", function (e) {
        e.preventDefault();

        var allDates = [];
        var dates = "";

        if (self.configs.hiddenDates.length > 0) {
          allDates = _.concat(self.configs.activeDates, self.configs.hiddenDates);
        } else {
          allDates = _.concat(self.configs.activeDates, self.configs.rightHiddenDates);
          allDates = _.concat(self.configs.leftHiddenDates, allDates);
        }

        dates +=
          '<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">' +
          '<label class="col-6 col-form-label" style="text-align: left;">Exibir informações de pagamento?</label>' +
          '<div class="col-4">' +
          '<span class="m-switch m-switch--sm m-switch--icon">' +
          "<label>" +
          '<input type="checkbox" id="display_payments_method" value="1" name="method_payments" />' +
          '<span class=""></span>' +
          "</label>" +
          "</span>" +
          "</div>" +
          "</div>";

        dates +=
          '<div class="row printDates"><p class="col-sm-12">Selecione a data para efetuar a impressão, ou deixe em branco para todas:</p>';
        $.each(allDates, function () {
          dates +=
            '<button class="btn btn-default date-btn col-md-2" onclick="checkPrintDate(this);" data-check="N" data-date="' +
            this.date_hidden +
            '">';
          dates += this.date;
          dates +=
            '<i class="fa fa-check" style="float: right;margin-right: -25px;margin-top: -20px;font-size: 20px;display: none;"></i>';
          dates += "</button>";
        });
        dates += "</div>";

        $("#dates-print").html(dates);

        $("#m_printDate").modal("show");
      });

      $(document).on("click", "#btnModalPrint", function (e) {
        var printDates = [];
        var showPayment = false;
        var companyDescription = "";
        var i = 0;

        $.each($(".printDates > button"), function () {
          if ($(this).attr("data-check") == "S") {
            printDates.push($(this).attr("data-date"));
          }
        });

        if (printDates.length <= 0) {
          $.each($(".printDates > button"), function () {
            printDates.push($(this).attr("data-date"));
          });
        }

        if ($("#display_payments_method").is(":checked")) {
          showPayment = true;
        }

        if ($("#company_id").val().length > 0) {
          $.each(
            $(".select2-selection__rendered > .select2-selection__choice"),
            function () {
              i++;
              companyDescription += $(this).attr("title");

              if (i < $("#company_id").val().length) {
                companyDescription += ", ";
              }
            }
          );
        } else {
          companyDescription = "Todas as empresas";
        }

        printInnCash(self, printDates, showPayment, companyDescription);
      });

      ajustaHeaderFluxoPrevistoRealizado(this.paramsVisibility);

      //$(document).off('click', '.loadView');
      $(document).on("click", ".loadView", function (e) {
        var btn = $(this)[0];
        e.preventDefault();
        //self.setView($(this).attr('data-key'));
      });

      $("#btn-generate").click(function () {
        if (self.$session.get('user_config_autotoggle_paramns')) {
          self.hiddenParams();
        }

        var btn = $(this);

        const initialDate = $.trim($("#dateInitial > input").val());
        const finalDate = $.trim($("#dateFinish > input").val());

        if ( initialDate == "") {
          showNotification("Atenção", "A data inicial deve ser informada", "warning");
          return false;
        }

        if ( finalDate == "") {
          showNotification("Atenção", "A data final deve ser informada", "warning");
          return false;
        }

        blockPage("Carregando os dados...");

        const companiesId = $("#company_id").val();

        checkImportBeforeSearch(self, companiesId)
          .then(validateImporting)
          .then((data) => validatePaymentRemittance(data, initialDate, finalDate ))
          .then((data) => validateBillingRemittance(data, initialDate, finalDate))
          .then((data) => self.createDOM(btn))
          .catch((reason) => {
            if( reason instanceof ValidationError ) {
              showNotification("Atenção", reason.message, "warning");
            } else {
              showNotification("Atenção", 'Opa! Parece que algo saiu errado, tente recarregar a página', "danger");
            }
            unblockPage();
          });
      });

      $("#report_type").change(function () {
        if (this.value == "S") {
          $("#nivel").attr("disabled", false);
          $("#nivel").val("four");
          $("#nivel option:selected").text();
          $("#select2-nivel-container").attr("title", $("#nivel option:selected").text());
          $("#select2-nivel-container").html($("#nivel option:selected").text());
        } else {
          $("#nivel").attr("disabled", true);
        }
      });

      $(document).off("click", "i.fa.fa-check");
      $(document).on("click", "i.fa.fa-check", function (e) {
        e.preventDefault();
        checkIten(self, this);
      });

      $(document).off("click", "#fluxActions");
      $(document).on("click", "#fluxActions", function (e) {
        e.preventDefault();
      });

      $(document).off("click", "i.la.la-trash-o.simulation");
      $(document).on("click", "i.la.la-trash-o.simulation", function (e) {
        e.preventDefault();
        removeSimulation(self, this);
      });

      $(document).off("click", "i.la.la-check-circle-o");
      $(document).on("click", "i.la.la-check-circle-o", function (e) {
        e.preventDefault();
        addSimulation(self, this);
      });

      $(document).off("keyup", "input.values");
      $(document).on("keyup", "input.values", function () {
        var $this = $(this);
        var rowId = $this.attr("id").split("_")[1];
        var currentValueInput = 0;
        var currentValueContainerLevelOne = 0;
        var currentValueContainerLevelTwo = 0;
        var total = 0;

        if ($this.val() != "") {
          currentValueInput = parseFloat($this.val());
        }

        if ($.trim($("#container_level_one_" + rowId).text()) != "") {
          currentValueContainerLevelOne = parseFloat(
            $("#container_level_one_" + rowId).text()
          );
        }

        if ($.trim($("#container_level_two_" + rowId).text()) != "") {
          currentValueContainerLevelTwo = parseFloat(
            $("#container_level_two_" + rowId).text()
          );
        }

        total = currentValueInput + currentValueContainerLevelTwo;

        $("#container_level_one_" + rowId).html(total);
        $("#container_level_two_" + rowId).html(total);
      });

      $(document).off("keyup", "input.containerLevelThreeSimulation");
      $(document).on("keyup", "input.containerLevelThreeSimulation", function () {
        var $this = $(this);
        var rowId = $this.attr("id");

        calculate(self, rowId, "level-three", "");
      });

      $(document).off("click", "i.fa.fa-plus-circle");
      $(document).on("click", "i.fa.fa-plus-circle", function () {
        addRowSimulation(self, this);
      });

      $(document).off("click", "#saveView");
      $(document).on("click", "#saveView", function () {
        $("#m_saveView").modal("show");
      });

      $(document).off("click", "#deleteView");
      $(document).on("click", "#deleteView", function () {
        self.deleteView();
      });

      $(document).off("click", "#updateDate");
      $(document).on("click", "#updateDate", function () {        
        self.$refs.UpdateInformation.openModal({
          operation: "updateDate",
          erp_table_code: $(this).closest("fields_to_edit").data("erp_table_code"),
          erp_table: $(this).closest("fields_to_edit").data("erp_table"),
          keys: $(this).closest("fields_to_edit").data("keys"),
          tax_keys: $(this).closest("fields_to_edit").data("tax_keys"),
          id: $(this).closest("fields_to_edit").data("internal_table_id"),
          id_field: $(this).closest("fields_to_edit").data("id_field"),
          keyId: $(this).closest("fields_to_edit").data("key-id"),
          check: $(this).closest("fields_to_edit").attr("check"),
          type: $(this).closest("fields_to_edit").data("identify-value"),
          date: $(this).closest("fields_to_edit").data("date"),
          id_customer: $(this).closest("fields_to_edit").data("id-customer"),
          payer_id_customer: $(this).closest("fields_to_edit").data("payer-id-customer"),
          payer_company_id: $(this).closest("fields_to_edit").data("payer-company-id"),
          company_token: $(this).closest("fields_to_edit").data("company-token"),
          branch_token: $(this).closest("fields_to_edit").data("branch-token"),
          source_table: $(this).closest("fields_to_edit").data("source-table"),
          value_original: $(this).closest("fields_to_edit").data("value-original"),
          company_id: ($(this).closest("fields_to_edit").data("company-id")),
          self: self,
        });
      });

      $(document).off("click", "#updateAllDates");
      $(document).on("click", "#updateAllDates", function () {
        self.$refs.UpdateInformation.openModal({
          operation: "updateAllDates",
          erp_table_code: $(this).closest("fields_to_edit").data("erp_table_code"),
          erp_table: $(this).closest("fields_to_edit").data("erp_table"),
          keys: $(this).closest("fields_to_edit").data("keys"),
          tax_keys: $(this).closest("fields_to_edit").data("tax_keys"),
          id: $(this).closest("fields_to_edit").data("internal_table_id"),
          id_field: $(this).closest("fields_to_edit").data("id_field"),
          keyId: $(this).closest("fields_to_edit").data("key-id"),
          check: $(this).closest("fields_to_edit").attr("check"),
          type: $(this).closest("fields_to_edit").data("identify-value"),
          date: $(this).closest("fields_to_edit").data("date"),
          value_original: $(this).closest("fields_to_edit").data("value-original"),
          self: self,
        });
      });

      $(document).off("click", "#updatePayment");
      $(document).on("click", "#updatePayment", function () {
        self.$refs.UpdateInformation.openModal({
          operation: "updatePayment",
          type: $(this).closest("fields_to_edit").data("identify-value"),
          id: $(this).closest("fields_to_edit").data("internal_table_id"),
          id_field: $(this).closest("fields_to_edit").data("id_field"),
          keys: $(this).closest("fields_to_edit").data("keys"),
          erp_table: $(this).closest("fields_to_edit").data("erp_table"),
          erp_table_code: $(this).closest("fields_to_edit").data("erp_table_code"),
          payment_method: $(this).closest("fields_to_edit").data("payment_method"),
          id_customer: $(this).closest("fields_to_edit").data("id-customer"),
          payer_id_customer: $(this).closest("fields_to_edit").data("payer-id-customer"),
          payer_company_id: $(this).closest("fields_to_edit").data("payer-company-id"),
          company_token: $(this).closest("fields_to_edit").data("company-token"),
          branch_token: $(this).closest("fields_to_edit").data("branch-token"),
          source_table: $(this).closest("fields_to_edit").data("source-table"),
          company_id: ($(this).closest("fields_to_edit").data("company-id")),
          self: self,
        });
      });

      $(document).off("click", "updateCoinTax");
      $(document).on("click", "#updateCoinTax", function () {
        let coinTax = $(this).closest("fields_to_edit").data("coin-tax");
        let value = $(this).closest("fields_to_edit").data("value");

        if (coinTax > 0) {
          value = parseFloat(value) / parseFloat(coinTax);
        }

        self.$refs.UpdateInformation.openModal({
          operation: "updateCoinTax",
          idField: $(this).closest("fields_to_edit").data("id_field"),
          keyId: $(this).closest("fields_to_edit").data("key-id"),
          date: $(this).closest("fields_to_edit").data("date"),
          id_customer: $(this).closest("fields_to_edit").data("id-customer"),
          payer_id_customer: $(this).closest("fields_to_edit").data("payer-id-customer"),
           payer_company_id: $(this).closest("fields_to_edit").data("payer-company-id"),
          company_token: $(this).closest("fields_to_edit").data("company-token"),
          branch_token: $(this).closest("fields_to_edit").data("branch-token"),
          source_table: $(this).closest("fields_to_edit").data("source-table"),
          identifyValue: $(this).closest("fields_to_edit").data("identify-value"),
          valueOriginal: $(this).closest("fields_to_edit").data("value-original"),
          company_id: ($(this).closest("fields_to_edit").data("company-id")),
          value: value,
          coin: $(this).closest("fields_to_edit").data("coin"),
          coinTax: coinTax,
          self: self,
        });
      });

      $(document).off("click", "#updateValue, #updateValueBank");
      $(document).on("click", "#updateValue, #updateValueBank", function () {
        self.$refs.UpdateInformation.openModal({
          operation: "updateValue",
          idField: $(this).closest("fields_to_edit").data("id_field"),
          keyId: $(this).closest("fields_to_edit").data("key-id"),
          date: $(this).closest("fields_to_edit").data("date"),
          identifyValue: $(this).closest("fields_to_edit").data("identify-value"),
          value: $("#" + $(this).closest("fields_to_edit").data("id_field"))
            .find("span")
            .html(),
          keys: $(this).closest("fields_to_edit").data("keys"),
          erp_table: $(this).closest("fields_to_edit").data("erp_table"),
          erp_table_code: $(this).closest("fields_to_edit").data("erp_table_code"),
          idSplit: $(this).closest("fields_to_edit").data("id_field").split("_"),
          internalId: $(this).closest("fields_to_edit").data("internal_table_id"),
          id_customer: $(this).closest("fields_to_edit").data("id-customer"),
          payer_id_customer: $(this).closest("fields_to_edit").data("payer-id-customer"),
          payer_company_id: $(this).closest("fields_to_edit").data("payer-company-id"),
          company_token: $(this).closest("fields_to_edit").data("company-token"),
          branch_token: $(this).closest("fields_to_edit").data("branch-token"),
          source_table: $(this).closest("fields_to_edit").data("source-table"),
          company_id: ($(this).closest("fields_to_edit").data("company-id")),
          self: self,
        });
      });

      $(document).off("click", "#updateTax");
      $(document).on("click", "#updateTax", function () {
        let interest_rate = numeral(0).format("0,0.00");
        let tax_keys = $(this).closest("fields_to_edit").data("tax_keys");

        if (tax_keys) {
          interest_rate = numeral(tax_keys.interest_rate).format("0,0.00");
        }

        self.$refs.UpdateInformation.openModal({
          operation: "updateTax",
          level: $(this).closest("fields_to_edit").data("id_field").split("_")[1],
          idField: $(this).closest("fields_to_edit").data("id_field"),
          keyId: $(this).closest("fields_to_edit").data("key-id"),
          date: $(this).closest("fields_to_edit").data("date"),
          identifyValue: $(this).closest("fields_to_edit").data("identify-value"),
          value_original: $(this).closest("fields_to_edit").data("value-original"),
          value: $(this).closest("fields_to_edit").data("value"),
          erp_table: $(this).closest("fields_to_edit").data("erp_table"),
          erp_table_code: $(this).closest("fields_to_edit").data("erp_table_code"),
          id_customer: $(this).closest("fields_to_edit").data("id-customer"),
          payer_id_customer: $(this).closest("fields_to_edit").data("payer-id-customer"),
          payer_company_id: $(this).closest("fields_to_edit").data("payer-company-id"),
          company_token: $(this).closest("fields_to_edit").data("company-token"),
          branch_token: $(this).closest("fields_to_edit").data("branch-token"),
          source_table: $(this).closest("fields_to_edit").data("source-table"),
          company_id: ($(this).closest("fields_to_edit").data("company-id")),
          tax_keys: tax_keys,
          interest_rate: interest_rate,
          self: self,
        });
      });

      $(document).off("click", "#updateIncrease");
      $(document).on("click", "#updateIncrease", function () {
        let keys = $(this).closest("fields_to_edit").data("keys");
        let erp_table = $(this).closest("fields_to_edit").data("erp_table");
        let description = "";
        let valueIncrease = 0;
        let valueDecrease = 0;
        let default_value = 0;
        let default_type = "A";

        if (erp_table == "SE2") {
          if (keys.E2_ACRESC || keys.E2_DECRESC) {
            if (keys.E2_ACRESC > 0) {
              description = "Acréscimo";
              valueIncrease = keys.E2_ACRESC;
              default_value = valueIncrease;
              default_type = "A";
            }
            if (keys.E2_DECRESC > 0) {
              description = "Decréscimo";
              valueDecrease = keys.E2_DECRESC;
              default_value = valueDecrease;
              default_type = "D";
            }
          }
        } else if (erp_table == "SE1") {
          if (keys.E1_ACRESC || keys.E1_DECRESC) {
            if (keys.E1_ACRESC > 0) {
              description = "Acréscimo";
              valueIncrease = keys.E1_ACRESC;
              default_value = valueIncrease;
              default_type = "A";
            }
            if (keys.E1_DECRESC > 0) {
              description = "Decréscimo";
              valueDecrease = keys.E1_DECRESC;
              default_value = valueDecrease;
              default_type = "D";
            }
          }
        }

        self.$refs.UpdateInformation.openModal({
          operation: "updateIncrease",
          date: $(this).closest("fields_to_edit").data("date"),
          keys: keys,
          tax_keys: $(this).closest("fields_to_edit").data("tax_keys"),
          erp_table: erp_table,
          erp_table_code: $(this).closest("fields_to_edit").data("erp_table_code"),
          idField: $(this).closest("fields_to_edit").data("id_field"),
          id: $(this).closest("fields_to_edit").data("internal_table_id"),
          identifyValue: $(this).closest("fields_to_edit").data("identify-value"),
          valueOriginal: $(this).closest("fields_to_edit").data("value-original"),
          level: $(this).closest("fields_to_edit").data("id_field").split("_")[1],
          description: description,
          valueIncrease: valueIncrease,
          valueDecrease: valueDecrease,
          default_value: default_value,
          default_type: default_type,
          id_customer: $(this).closest("fields_to_edit").data("id-customer"),
          payer_id_customer: $(this).closest("fields_to_edit").data("payer-id-customer"),
          payer_company_id: $(this).closest("fields_to_edit").data("payer-company-id"),
          company_token: $(this).closest("fields_to_edit").data("company-token"),
          branch_token: $(this).closest("fields_to_edit").data("branch-token"),
          source_table: $(this).closest("fields_to_edit").data("source-table"),
          company_id: ($(this).closest("fields_to_edit").data("company-id")),
          self: self,
        });
      });

      $(document).off(
        "click",
        "#updateComment, #updateCommentIcon, #updateCommentBank, #updateCommentIconBank"
      );
      $(document).on(
        "click",
        "#updateComment, #updateCommentIcon, #updateCommentBank, #updateCommentIconBank",
        function () {
          self.$refs.UpdateInformation.openModal({
            operation: "updateComment",
            idField: $(this).closest("fields_to_edit").data("id_field"),
            keyId: $(this).closest("fields_to_edit").data("key-id"),
            date: $(this).closest("fields_to_edit").data("vencido")
              ? $(this).closest("fields_to_edit").data("dtoriginal")
              : $(this).closest("fields_to_edit").data("date"),
            identifyValue: $(this).closest("fields_to_edit").data("identify-value"),
            futureDate: $(this).closest("fields_to_edit").data("future_date"),
            companyId: ($(this).closest("fields_to_edit").data("company-id")),
            idCustomer: $(this).closest("fields_to_edit").data("id-customer"),
            self: self,
          });
        }
      );

      $(document).off("click", "#viewInformation");
      $(document).on("click", "#viewInformation", function (event) {
        self.$refs.ViewInformation.openModal({
          operation: "viewInformation",
          type: $(this).closest("fields_to_edit").data("identify-value"),
          id: $(this).closest("fields_to_edit").data("internal_table_id"),
          date: $(this).closest("fields_to_edit").data("date"),
          grouperTwoId: $(this).closest("fields_to_edit").data("id_field").split("_")[4],
        });
      });

      $(document).off("click", "#left-column");
      $(document).on("click", "#left-column", function (event) {
        adjustRangeDate(_, self, "left");
      });

      $(document).off("click", "#right-column");
      $(document).on("click", "#right-column", function (event) {
        adjustRangeDate(_, self, "right");
      });

      const openModalsFn = function (el, modalRef) {
        let searchData = $(el).attr("data-date");
        let searchGroupId = $(el).attr("group-id");
        let totalSaldoDiff = 0;
        let typeCnab = "CP";
        let dataKeys = [];
        let dataIds = [];
        let dataDescriptions = [];
        let totalSelected = 0;
        let countSelected = 0;
        let lvlDescription = $(el)
          .parent()
          .parent()
          .parent()
          .attr("data-leveldescription");
        let companyId = 0;

        if ($("#company_id").val().length == 1) {
          companyId = $("#company_id").val();
        } else {
          showNotification("Atenção", "Selecione apenas 1 empresa.", "warning");
          return;
        }

        $("#" + searchGroupId)
          .find("fields_to_edit[data-date=" + searchData + "][check='S']")
          .each(function () {
            if (
              this.getAttribute("data-keys") &&
              this.getAttribute("data-keys") != "undefined"
            ) {
              dataKeys.push(this.getAttribute("data-keys"));
              dataIds.push(this.getAttribute("data-key-id"));

              let payment_method = this.getAttribute("data-payment_method");
              payment_method =
                payment_method && payment_method != "undefined"
                  ? JSON.parse(this.getAttribute("data-payment_method"))
                  : null;

              let bank = this.getAttribute("data-bank");
              bank =
                bank && bank != "undefined"
                  ? JSON.parse(this.getAttribute("data-bank"))
                  : null;

              dataDescriptions.push({
                description: this.getAttribute("description"),
                levelThreeDescription: this.getAttribute("level-three-description"),
                lvlDescription: lvlDescription,
                value: numeral(this.getAttribute("data-value")).format("0,0.00"),
                cod_bar: this.getAttribute("data-cod_bar"),
                payment_method: payment_method,
                bank: bank,
              });

              totalSelected += parseFloat(this.getAttribute("data-value-original"));

              countSelected++;

              if (this.getAttribute("data-identify-value").trim() == "CR") {
                totalSaldoDiff +=
                  parseFloat(this.getAttribute("data-value-original")) *
                  parseFloat(this.getAttribute("date-diff"));
                typeCnab = "CR";
              }
            }
          });

        if (dataKeys.length == 0) {
          showNotification(
            "Atenção",
            "Não foram encontrados títulos liberados.",
            "warning"
          );
          return;
        }

        if (typeCnab == "CR") {
          totalSaldoDiff = numeral(totalSaldoDiff / totalSelected).format("0,0.00");
        }

        modalRef.openModal({
          totalSelected: totalSelected,
          cnabGroupId: searchGroupId,
          dataDescriptions: dataDescriptions,
          countSelected: countSelected,
          totalSaldoDiff: totalSaldoDiff,
          companyId: companyId,
          dataKeys: dataKeys,
          dataIds: dataIds,
          typeCnab: typeCnab,
          initialReferenceDate: $("#dateInitial > input").val(),
          finalReferenceDate: $("#dateFinish > input").val(),
        });
      }

      $(document).off("click", "#generate_cnab_btn");
      $(document).on("click", "#generate_cnab_btn", function() {openModalsFn(this, self.$refs.GenerateCnab)});

    },
    createDOM: function (btn) {
      var self = this;
      var companys = "ALL";
      var report_type = $("#report_type").val();
      var approvalFilter = $("#approvalFilter").val();
      var bank_balance = $("#saldo_bancario").val();
      let nivel = $("#nivel").val() || 'four';

      $("#fluxo").remove();

      if ($("#company_id").val().length > 0) {
        companys = $("#company_id").val();
      }

      var filters = {
        dateInitial: $("#dateInitial > input").val(),
        dateFinish: $("#dateFinish > input").val(),
        company_id: companys,
        report_type: $("#report_type").val(),
        nivel,
        approvalFilter: $("#approvalFilter").val(),
        bank_balance: $("#saldo_bancario").val(),
      };

      self.view.filters = filters;

      var dateFinish = "";

      $.ajax({
        method: "POST",
        url: API_BASE + "loadinncash",
        headers: {
          token: self.$session.get("token"),
        },
        data: {
          dateInitial: $("#dateInitial > input").val(),
          dateFinish: $("#dateFinish > input").val(),
          company_id: companys,
          nivel,
          approval_filter: $("#approvalFilter").val(),
          report_type: $("#report_type").val(),
          bank_balance: $("#saldo_bancario").val(),
        },
      })
        .done(function (result) {
          unblockPage();
          btn
            .removeClass("m-loader m-loader--right m-loader--light")
            .attr("disabled", false);
          self.initView();
          self.loadViewData(result);
        })
        .fail(function (error) {
          unblockPage();
          showNotification(
            "Atenção",
            "Oops! Ocorreu um erro ao gerar os dados.",
            "danger"
          );
          btn
            .removeClass("m-loader m-loader--right m-loader--light")
            .attr("disabled", false);
          console.log(error);
        });
    },

    loadViewData: function (result) {
      var self = this;
      var html = "";
      var report_type = $("#report_type").val();
      var nivel = $("#nivel").val();
      var expanded =
        report_type == "A" ? "fa-chevron-down" : "fa-chevron-right collapsed";

      $("#fluxo").html("");

      if (result.error == false) {
        var buttonView = "";
        var viewName = "";

        if (self.view.id) {
          buttonView =
            '<li class="m-portlet__nav-item" aria-expanded="true">' +
            '<button type="button" id="deleteView" class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" style="width: 44px;height: 44px;" data-toggle="m-tooltip" data-placement="bottom" data-original-title=" Excluir a visão ">' +
            '<i class="la la-trash-o" style="font-size:25px;" />' +
            "</button>" +
            "</li>" +
            '<li class="m-portlet__nav-item" aria-expanded="true">' +
            '<button type="button" id="printFluxo" class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" style="width: 44px;height: 44px;" data-toggle="m-tooltip" data-placement="bottom" data-original-title=" Imprimir o fluxo ">' +
            '<i class="la la-print" style="font-size:25px;" />' +
            "</button>" +
            "</li>";

          viewName =
            '<li class="m-portlet__nav-item text-left" style="padding-right: 15px;">' +
            '<div style="border: 1px solid #eee;border-radius: 25px;padding: 0px 20px 0px 20px;">' +
            "<small>Visão:</small><br><strong>" +
            self.view.description +
            "</strong>" +
            "<div>" +
            "</li>";
        } else {
          buttonView =
            '<li class="m-portlet__nav-item" aria-expanded="true">' +
            '<button type="button" id="printFluxo" class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" style="width: 44px;height: 44px;" data-toggle="m-tooltip" data-placement="bottom" data-original-title=" Imprimir o fluxo ">' +
            '<i class="la la-print" style="font-size:25px;" />' +
            "</button>" +
            "</li>";
        }
        //Create portlet
        $("#params").after(
          '<div id="fluxo" class="m-portlet m-portlet--mobile">' +
            '<div class="m-portlet__head">' +
            '<div class="m-portlet__head-caption">' +
            '<div class="m-portlet__head-title">' +
            '<h3 class="m-portlet__head-text">Fluxo de Caixa</h3>' +
            "</div>" +
            "</div>" +
            '<div class="m-portlet__head-tools">' +
            '<ul class="m-portlet__nav">' +
            viewName +
            buttonView +
            "</ul>" +
            "</div>" +
            "</div>" +
            '<div class="m-portlet__body" id="fluxo-body">' +
            '<div class="row display-print" style="margin-bottom:30px;"></div>' +
            "</div>" +
            "</div>"
        );

        $('[data-toggle="m-tooltip"]').tooltip();
        self.configs.totalDays = result.data[0].total_dates;
        self.configs.totalActiveDays = result.data[0].dates.total_active_dates;
        self.configs.totalHiddenDays = result.data[0].dates.total_hidden_dates;
        self.configs.firstDateActive = result.data[0].dates.first_date_active;
        self.configs.lastDateActive = result.data[0].dates.last_date_active;
        self.configs.activeDates = result.data[0].dates.active_dates;
        self.configs.hiddenDates = result.data[0].dates.hidden_dates;

        //Create layout header
        html +=
          '<div class="row header-inncash">';
        html +=
          '<div class="col-sm-2" style="padding-right:0;padding-left:0;">&nbsp;</div>';
        html +=
          '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;padding-top:0;">';
        if (self.configs.hiddenDates.length > 0) {
          html +=
            '<button type="button" id="left-column" class="btn m-btn--pill btn-secondary btn-sm">';
          html += '<i class="la la-angle-left" style="padding-top:5px;" />';
          html += "</button>";
        } else {
          html += "&nbsp;";
        }
        html += "</div>";
        html +=
          '<div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">';
        html += '<div class="row rangeDates" style="padding-right:0;padding-left:0;">';
        html +=
          '<div class="col-sm-1" style="padding-right:0;padding-left:0;">&nbsp;</div>';

        $.each(result.data[0].dates.active_dates, function () {
          html +=
            '<div class="col-sm-2 dates containerDate" dateValue="' +
            this.date_hidden +
            '" data-date="' +
            this.date_hidden +
            '" data-date-br="' +
            this.date +
            '" style="padding-right:0;padding-left:30px;">' +
            this.date +
            "<br>" +
            this.week +
            "</div>";
        });

        if (result.data[0].dates.total_hidden_dates > 0) {
          $.each(result.data[0].dates.hidden_dates, function () {
            html +=
              '<div class="col-sm-2 dates containerDate" dateValue="' +
              this.date_hidden +
              '" data-date="' +
              this.date_hidden +
              '" data-date-br="' +
              this.date +
              '" style="padding-right:0;padding-left:30px;display:none;">' +
              this.date +
              "<br>" +
              this.week +
              "</div>";
          });
        }

        html +=
          '<div class="col-sm-1" style="padding-right:0;padding-left:0;">&nbsp;</div>';
        html += '<div class="data_payment col-sm-4"></div>';
        html += '<div class="data_payment col-sm-6"></div>';
        html += "</div>";
        html += "</div>";
        html +=
          '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;padding-top:0;">';
        if (self.configs.hiddenDates.length > 0) {
          html +=
            '<button type="button" id="right-column" class="btn m-btn--pill btn-secondary btn-sm">';
          html += '<i class="la la-angle-right" style="padding-top:5px;" />';
          html += "</button>";
        } else {
          html += "&nbsp;";
        }
        html += "</div>";
        html += "</div>";

        $("#fluxo > .m-portlet__body").append(html);

        $.each(result.data[1], function () {
          var itensHtml = "";
          var type = this.type;
          var qtRegistros = isNaN(parseInt(this.total_records))
            ? 0
            : parseInt(this.total_records);

          switch (type) {
            case "ACU":
              console.log("Gerando Acumulado...");
              break;
            case "ENT":
              console.log("Gerando Entradas...");
              break;
            case "SAI":
              console.log("Gerando Saidas...");
              break;
            default:
              break;
          }

          itensHtml +=
            '<div class="row levels level-main" data-level="' +
            type +
            '" style="background-color: #498cc5;color: white;font-weight: bold;">';
          itensHtml += '<div class="col-sm-3" style="padding-right:0;padding-left:0;">';
          if (type != "ACU") {
            itensHtml +=
              '<i class="fa ' +
              expanded +
              ' item-collapse" data-toggle="collapse" data-target="#collapse_' +
              type +
              '" style="padding-top: 3px;float: left;cursor: pointer;" />';
          }
          itensHtml +=
            '<span class="description" style="float: left;padding-left: 3px; width: 86%; white-space: normal;"><strong>' +
            this.description +
            "</strong></span>";
          itensHtml += "</div>";
          itensHtml +=
            '<div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">';
          itensHtml += '<div class="row" style="padding-right:0;padding-left:0;">';
          itensHtml +=
            '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';

          $.each(self.configs.activeDates, function () {
            itensHtml +=
              '<div id="' +
              type +
              "_level-main_" +
              this.date_hidden +
              '" class="col-sm-2 text-right showOptions containerLevelMain containerDate" data-date="' +
              this.date_hidden +
              '" style="padding-right:0;padding-left:0;">';
            itensHtml +=
              '<fields_to_edit data-id_field="' +
              type +
              "_level-main_" +
              this.date_hidden +
              '" check="N" data-container_each="">';
            itensHtml +=
              '<i class="fa fa-check" style="float: left;cursor: pointer;margin-left: 5px;display: none;color: green;"></i>';
            itensHtml += "<span>&nbsp;</span>";
            itensHtml += "</fields_to_edit>";
            itensHtml += "</div>";
          });

          $.each(self.configs.hiddenDates, function () {
            itensHtml +=
              '<div id="' +
              type +
              "_level-main_" +
              this.date_hidden +
              '" class="col-sm-2 text-right showOptions containerLevelMain containerDate" data-date="' +
              this.date_hidden +
              '" style="padding-right:0;padding-left:0;display:none;">';
            itensHtml +=
              '<fields_to_edit data-id_field="' +
              type +
              "_level-main_" +
              this.date_hidden +
              '" check="N" data-container_each="">';
            itensHtml +=
              '<i class="fa fa-check" style="float: left;cursor: pointer;margin-left: 5px;display: none;color: green;"></i>';
            itensHtml += "<span>&nbsp;</span>";
            itensHtml += "</fields_to_edit>";
            itensHtml += "</div>";
          });

          itensHtml +=
            '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';
          itensHtml += "</div>";
          itensHtml += "</div>";
          itensHtml += "</div>";
          if (type != "ACU") {
            itensHtml +=
              report_type == "S"
                ? '<div id="collapse_' + type + '" class="collapse"></div>'
                : '<div id="collapse_' + type + '" class="collapse show"></div>';
          }

          $("#fluxo > .m-portlet__body").append(itensHtml);

          $.each(this.data_values, function () {
            var valueType = this.value;

            if (type == "ACU") {
              valueType = 0;
            }

            $("#" + type + "_level-main_" + this.date)
              .find("span")
              .html(numeral(0).format("0,0.00"));

            if (type == "SAI") {
              $("#" + type + "_level-main_" + this.date)
                .parent()
                .append('<div class="data_payment col-sm-4">Forma de pagamento</div>');
              $("#" + type + "_level-main_" + this.date)
                .parent()
                .append('<div class="data_bank col-sm-6">Dados bancários</div>');
            }

            $("#" + type + "_level-main_" + this.date)
              .parent()
              .find(".data_payment")
              .not(":first")
              .remove();
            $("#" + type + "_level-main_" + this.date)
              .parent()
              .find(".data_bank")
              .not(":first")
              .remove();
          });

          if (qtRegistros > 0) {
            $.each(this.data, function () {
              var itensHtml = "";
              var idLine = this.level_one.id;
              var dataType = this.level_one.type;
              var agroupOne = idLine;
              var lvlDescription = this.level_one.description;

              itensHtml +=
                '<div class="row levels level-one" data-level="' +
                agroupOne +
                '" style="background-color: #9bbfd8;font-weight: bold;">';
              itensHtml +=
                '<div class="col-sm-3" style="padding-right:0;padding-left:5px;">';
              itensHtml +=
                '<i class="fa ' +
                expanded +
                ' item-collapse" data-toggle="collapse" data-target="#collapse_' +
                agroupOne +
                '" style="padding-top: 3px;float: left;cursor: pointer;" />';
              itensHtml +=
                '<span class="description" style="float: left;padding-left: 3px;width: 185px;">' +
                this.level_one.description +
                "</span>";
              itensHtml += "</div>";
              itensHtml +=
                '<div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">';
              itensHtml += '<div class="row" style="padding-right:0;padding-left:0;">';
              itensHtml +=
                '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';

              let dropCnab = `<div style="padding-left: 20px;" class="dropdown">
                <i class="fa fa-bars" id="dropdownMenuButton" data-toggle="dropdown" style="float: left;cursor: pointer;margin-left: 5px;display:none;" />
                <div class="dropdown-menu fix-dropdown-position" aria-labelledby="dropdownMenuButton">
                  <a id="generate_cnab_btn" class="dropdown-item" href="" style="padding: .3rem 1.2rem" data-toggle="modal" data-date="{{data-date}}" group-id="collapse_${agroupOne}">Gerar Remessa Bancária</a>
                </div>
              </div>`;

              $.each(self.configs.activeDates, function () {
                dropCnab = dropCnab.replace("{{data-date}}", this.date_hidden);
                itensHtml +=
                  '<div id="' +
                  type +
                  "_level-one_" +
                  this.date_hidden +
                  "_" +
                  agroupOne +
                  '" class="col-sm-2 text-right containerLevelOne containerDate showOptions" data-date="' +
                  this.date_hidden +
                  '" style="padding-right:0;padding-left:0;" data-type="' +
                  dataType +
                  '" data-levelDescription="' +
                  lvlDescription +
                  '">';
                if (["account_payment", "account_receive"].indexOf(dataType) != -1) {
                  itensHtml += dropCnab;
                }
                itensHtml += "<span>" + numeral(this.value).format("0,0.00") + "</span>";
                itensHtml += "</div>";
              });

              $.each(self.configs.hiddenDates, function () {
                dropCnab = dropCnab.replace("{{data-date}}", this.date_hidden);
                itensHtml +=
                  '<div id="' +
                  type +
                  "_level-one_" +
                  this.date_hidden +
                  "_" +
                  agroupOne +
                  '" class="col-sm-2 text-right containerLevelOne containerDate showOptions" data-date="' +
                  this.date_hidden +
                  '" style="padding-right:0;padding-left:0;display:none;" data-type="' +
                  dataType +
                  '" data-levelDescription="' +
                  lvlDescription +
                  '">';
                if (["account_payment", "account_receive"].indexOf(dataType) != -1) {
                  itensHtml += dropCnab;
                }
                itensHtml += "<span>" + numeral(this.value).format("0,0.00") + "</span>";
                itensHtml += "</div>";
              });

              itensHtml +=
                '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';
              itensHtml += "</div>";
              itensHtml += "</div>";
              itensHtml += "</div>";
              itensHtml +=
                '<div id="collapse_' + agroupOne + '" class="collapse show"></div>';

              $("#collapse_" + type).append(itensHtml);

              $.each(this.level_one.data_values, function () {
                $("#" + type + "_level-one_" + this.date + "_" + agroupOne)
                  .parent()
                  .append('<div class="data_payment col-sm-10">&nbsp;</div>');
                $("#" + type + "_level-one_" + this.date + "_" + agroupOne)
                  .parent()
                  .find(".data_payment")
                  .not(":first")
                  .remove();
              });

              $.each(this.level_two, function () {
                var itensHtml = "";
                var idLine = this.id;
                var idForSimulation = idLine;
                var agroupTwo = "_" + idLine;
                var levelTwoType = this.type;
                var user_edit_value = this.user_edit_value;
                var identifyValues = this.identify_values;
                var dataType = this.type;
                var lvlDescription = this.description;

                itensHtml +=
                  '<div class="row levels level-two" data-level="' +
                  agroupOne +
                  agroupTwo +
                  '" style="background-color: #bbd1e0;font-weight: bold;">';
                itensHtml +=
                  '<div class="col-sm-3" style="padding-right:0;padding-left:10px;">';
                itensHtml +=
                  '<i class="fa ' +
                  expanded +
                  ' item-collapse" data-toggle="collapse" data-target="#collapse_' +
                  agroupOne +
                  agroupTwo +
                  '" style="padding-top: 3px;float: left;cursor: pointer;" />';

                itensHtml +=
                  '<span class="description" style="float: left;padding-left: 3px;min-width: 25%;">' +
                  this.description +
                  "</span>";

                if (this.simulation && self.user_include_simulation) {
                  itensHtml +=
                    '<i class="fa fa-plus-circle mx-2" rowId="' +
                    this.id +
                    '" data-type="' +
                    type +
                    '" data-agroup="' +
                    agroupOne +
                    agroupTwo +
                    '" style="font-size: 18px;float: left;color: green;cursor: pointer; margin: 0.5% 12px;"></i>';
                } else {
                  itensHtml += "&nbsp;";
                }

                itensHtml += "</div>";
                itensHtml +=
                  '<div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">';
                itensHtml += '<div class="row" style="padding-right:0;padding-left:0;">';
                itensHtml +=
                  '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';

                $.each(self.configs.activeDates, function () {
                  itensHtml +=
                    '<div id="' +
                    type +
                    "_level-two_" +
                    this.date_hidden +
                    "_" +
                    agroupOne +
                    agroupTwo +
                    '" class="col-sm-2 text-right showOptions containerLevelTwo containerDate" data-date="' +
                    this.date_hidden +
                    '" data-identify_values="' +
                    identifyValues +
                    '" data-type="' +
                    dataType +
                    '" style="padding-right:0;padding-left:0;">&nbsp;</div>';
                });

                $.each(self.configs.hiddenDates, function () {
                  itensHtml +=
                    '<div id="' +
                    type +
                    "_level-two_" +
                    this.date_hidden +
                    "_" +
                    agroupOne +
                    agroupTwo +
                    '" class="col-sm-2 text-right showOptions containerLevelTwo containerDate" data-date="' +
                    this.date_hidden +
                    '" data-identify_values="' +
                    identifyValues +
                    '" style="padding-right:0;padding-left:0;display:none;">&nbsp;</div>';
                });

                itensHtml +=
                  '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';
                itensHtml += "</div>";
                itensHtml += "</div>";
                itensHtml += "</div>";
                itensHtml +=
                  '<div id="collapse_' +
                  agroupOne +
                  agroupTwo +
                  '" class="collapse show"></div>';

                $("#collapse_" + agroupOne).append(itensHtml);

                $.each(this.data_values, function () {
                  var containerToEach = "";

                  if (levelTwoType == "bank") {
                    containerToEach = "containerLevelThree";
                  } else {
                    containerToEach = "containerLevelFour";
                  }

                  itensHtml =
                    '<fields_to_edit data-id_field="' +
                    type +
                    "_level-two_" +
                    this.date +
                    "_" +
                    agroupOne +
                    agroupTwo +
                    '" check="N" data-container_each="' +
                    containerToEach +
                    '" data-levelDescription="' +
                    lvlDescription +
                    '" data-date="' +
                    this.date +
                    '" data-erp_table="' +
                    this.erp_table +
                    '">';

                  itensHtml += "<value_was_altered></value_was_altered>";
                  itensHtml +=
                    '<i class="fa fa-check" style="float: left;cursor: pointer;margin-left: 5px;display: none;color: green;"></i>';

                  if (
                     user_edit_value ||
                    (levelTwoType != "bank" &&
                      validateUserAccess(self, identifyValues, "change_interest_rate") )
                  ) {
                    itensHtml += '<div class="dropdown">';
                    itensHtml +=
                      '<i class="fa fa-bars" id="dropdownMenuButton" data-toggle="dropdown" style="float: left;cursor: pointer;margin-left: 5px;display:none;" />';
                    itensHtml +=
                      '<div class="dropdown-menu fix-dropdown-position" aria-labelledby="dropdownMenuButton">';

                    //if (validateUserAccess(self, identifyValues, 'change_date')) {
                    itensHtml +=
                      '<a class="dropdown-item" id="updateAllDates" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Data</a>';
                    //}

                    if (user_edit_value) {
                      itensHtml +=
                        '	<a class="dropdown-item" id="updateValue" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Valor</a>';
                    }

                    // if (
                    //   levelTwoType != "bank" &&
                    //   validateUserAccess(self, identifyValues, "change_interest_rate")
                    // ) {
                    //   itensHtml +=
                    //     '	<a class="dropdown-item" id="updateTax" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Taxa de Juros</a>';
                    // }

                    itensHtml +=
                      '	<a id="generate_cnab_btn" class="dropdown-item" href="" style="padding: .3rem 1.2rem" data-toggle="modal" data-date="' +
                      this.date +
                      '" group-id="collapse_' +
                      agroupOne +
                      agroupTwo +
                      '">Gerar Remessa Bancária</a>';

                    itensHtml += "</div>";
                    itensHtml += "</div>";
                  }

                  itensHtml += "<span>";
                  itensHtml += numeral(this.value).format("0,0.00");
                  itensHtml += "</span>";
                  itensHtml += "</fields_to_edit>";

                  $(
                    "#" + type + "_level-two_" + this.date + "_" + agroupOne + agroupTwo
                  ).html(itensHtml);
                  $("#" + type + "_level-two_" + this.date + "_" + agroupOne + agroupTwo)
                    .parent()
                    .append('<div class="data_payment col-sm-10">&nbsp;</div>');
                  $("#" + type + "_level-two_" + this.date + "_" + agroupOne + agroupTwo)
                    .parent()
                    .find(".data_payment")
                    .not(":first")
                    .remove();
                });

                $.each(this.level_three, function () {
                  var itensHtml = "";
                  var idLine = this.id;
                  var agroupThree = "_" + idLine;
                  var idLineLevelThree = idLine + "_";
                  var identifyValues = this.identify_values;
                  var dataType = identifyValues;
                  var keyId = this.key_id;
                  var boldCss = "";

                  var levelThreeDescription = this.description;

                  if (identifyValues != "BC") {
                    boldCss = "font-weight: bold; background-color: #f0f4f7;";
                  }

                  itensHtml +=
                    '<div class="row levels level-three" data-level="' +
                    agroupOne +
                    agroupTwo +
                    agroupThree +
                    '" data-type="' +
                    identifyValues +
                    '" style="' +
                    boldCss +
                    '">';
                  itensHtml +=
                    '<div class="col-sm-3" style="padding-right:0;padding-left:15px;">';
                  if (identifyValues != "BC") {
                    itensHtml +=
                      '<i class="fa ' +
                      expanded +
                      ' item-collapse" data-toggle="collapse" data-target="#collapse_' +
                      agroupOne +
                      agroupTwo +
                      agroupThree +
                      '" style="padding-top: 3px;float: left;cursor: pointer;" />';
                  }
                  itensHtml +=
                    '<span class="description" style="float: left;padding-left: 3px; width: 86%; white-space: normal;">' +
                    this.description +
                    "</span>";
                  itensHtml += "</div>";
                  itensHtml +=
                    '<div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">';
                  itensHtml +=
                    '<div class="row" style="padding-right:0;padding-left:0;">';
                  itensHtml +=
                    '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';

                  $.each(self.configs.activeDates, function () {
                    itensHtml +=
                      '<div id="' +
                      type +
                      "_level-three_" +
                      this.date_hidden +
                      "_" +
                      agroupOne +
                      agroupTwo +
                      agroupThree +
                      '" class="col-sm-2 text-right showOptions containerLevelThree containerDate" data-date="' +
                      this.date_hidden +
                      '" style="padding-right:0;padding-left:0;" check="N" data-identify_values="' +
                      identifyValues +
                      '">';
                    itensHtml += "&nbsp;";
                    itensHtml += "</div>";
                  });

                  $.each(self.configs.hiddenDates, function () {
                    itensHtml +=
                      '<div id="' +
                      type +
                      "_level-three_" +
                      this.date_hidden +
                      "_" +
                      agroupOne +
                      agroupTwo +
                      agroupThree +
                      '" class="col-sm-2 text-right showOptions containerLevelThree containerDate" data-date="' +
                      this.date_hidden +
                      '" style="padding-right:0;padding-left:0;display:none;" check="N" data-identify_values="' +
                      identifyValues +
                      '">';
                    itensHtml += "&nbsp;";
                    itensHtml += "</div>";
                  });

                  itensHtml +=
                    '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';
                  itensHtml += "</div>";
                  itensHtml += "</div>";
                  itensHtml += "</div>";
                  if (identifyValues != "BC") {
                    itensHtml +=
                      '<div id="collapse_' +
                      agroupOne +
                      agroupTwo +
                      agroupThree +
                      '" class="collapse show"></div>';
                  }

                  $("#collapse_" + agroupOne + agroupTwo).append(itensHtml);
                  
                  $.each(this.data_values, function (index_data_values) {
                    var htmlApproveShow = "display:none;";
                    var htmlApproveColor = "";
                    var htmlCommentShow = "display:none;";
                    var dataCheck = "N";

                    if (this.vencido) {
                      htmlApproveColor = "color:red;";
                    }

                    if (this.approved) {
                      htmlApproveShow = "display:block;";
                      htmlApproveColor = "color:green;";
                      dataCheck = "S";
                    }

                    if (this.have_comment == 1) {
                      htmlCommentShow = "display:block;";
                    }
                    var future_date = 0;
                    html = "";
                    html +=
                      '<fields_to_edit  data-erp_table_code="' +
                      this.erp_table_code +
                      '" data-identify-value="' +
                      identifyValues +
                      '" data-date="' +
                      this.date +
                      '" data-key-id="' +
                      keyId +
                      '" data-id_field="' +
                      type +
                      "_level-three_" +
                      this.date +
                      "_" +
                      agroupOne +
                      agroupTwo +
                      agroupThree +
                      '" check="' +
                      dataCheck +
                      '" data-erp_table="' +
                      this.erp_table +
                      '" data-keys=' +
                      JSON.stringify(this.erp_key) +
                      '" date-diff="' +
                      this.date_diff +
                      '" data-future_date="' +
                      future_date +
                      '" data-vencido="' +
                      this.vencido +
                      '" data-dtoriginal="' +
                      this.data_original +
                      '" data-dataold ="' +
                      this.data_old +
                      '" data-id-customer="' +
                      this.id_customer +
                      '" data-payer-id-customer="' +
                      this.payer_id_customer +
                      '" data-payer-company-id="' +
                      this.payer_company_id +
                      '" data-company-id="' +
                      this.company_id +
                      '" data-source-table="' +
                      this.source_table +
                      '" data-branch-token="' +
                      this.branch_token +
                      '">';
                    if (identifyValues == "BC") {
                      html += "<value_was_altered>";
                      if (this.value != this.value_original) {
                        html += "* ";
                      }
                      html += "</value_was_altered>";

                      //Permite alterar o valor apenas da primeira data.
                      if (index_data_values == 0) {
                        html +=
                          '<i class="fa fa-check" style="float: left;' +
                          htmlApproveShow +
                          'cursor: pointer;margin-left: 5px;color:green;"></i>';
                        html +=
                          '<i class="fa fa-comment-o" id="updateCommentIconBank" style="float: left;' +
                          htmlCommentShow +
                          'cursor: pointer;margin-left: 5px;" />';
                        html +=
                          '<i class="fa fa-bars" id="dropdownMenuButton" data-toggle="dropdown" style="float: left;display: none;cursor: pointer;margin-left: 5px;" />';
                        html +=
                          '<div class="dropdown-menu fix-dropdown-position" aria-labelledby="dropdownMenuButton">';
                        html += '	<div class="dropdown">';

                        //if (validateUserAccess(self, identifyValues, 'change_date')) {
                        //  html += '<a class="dropdown-item" id="updateAllDates" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar data</a>';
                        //}

                        if (
                          this.user_edit_value &&
                          validateUserAccess(self, identifyValues, "change_amount")
                        ) {
                          html +=
                            '<a class="dropdown-item" id="updateValueBank" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Valor</a>';
                        }

                        if (validateUserAccess(self, identifyValues, "include_comment")) {
                          html +=
                            '<a class="dropdown-item" id="updateCommentBank" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Comentário</a>';
                        }
                        html += "	</div>";
                        html += "</div>";
                      }
                    } else {
                      html +=
                        '<i class="fa fa-check" style="float: left;cursor: pointer;margin-left: 5px;display: none;color: green;"></i>';

                      if (user_edit_value) {
                        html += '<div class="dropdown">';
                        html +=
                          '<i class="fa fa-bars" id="dropdownMenuButton" data-toggle="dropdown" style="float: left;cursor: pointer;margin-left: 5px;display:none;" />';
                        html +=
                          '<div class="dropdown-menu fix-dropdown-position" aria-labelledby="dropdownMenuButton">';

                        //if (validateUserAccess(self, identifyValues, 'change_date')) {
                        html +=
                          '<a class="dropdown-item" id="updateAllDates" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Data</a>';
                        //}

                        html +=
                          '	<a class="dropdown-item" id="updateValue" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Valor</a>';
                        html += "</div>";
                        html += "</div>";
                      }
                    }

                    html +=
                      '<span class="valueLevelThree" style="' + htmlApproveColor + '">';
                    html += numeral(this.value).format("0,0.00");
                    html += "</span>";
                    html += "</fields_to_edit>";

                    $(
                      "#" +
                        type +
                        "_level-three_" +
                        this.date +
                        "_" +
                        agroupOne +
                        agroupTwo +
                        agroupThree
                    ).html(html);
                    $(
                      "#" +
                        type +
                        "_level-three_" +
                        this.date +
                        "_" +
                        agroupOne +
                        agroupTwo +
                        agroupThree
                    ).attr("check", dataCheck);
                    $(
                      "#" +
                        type +
                        "_level-three_" +
                        this.date +
                        "_" +
                        agroupOne +
                        agroupTwo +
                        agroupThree
                    )
                      .parent()
                      .append('<div class="data_payment col-sm-10">&nbsp;</div>');
                    $(
                      "#" +
                        type +
                        "_level-three_" +
                        this.date +
                        "_" +
                        agroupOne +
                        agroupTwo +
                        agroupThree
                    )
                      .parent()
                      .find(".data_payment")
                      .not(":first")
                      .remove();
                  });

                  $.each(this.level_four, function () {
                    var newId = this.id.replace(".", "");
                    var itensHtml = "";
                    var idLine = newId;
                    var agroupFour = "_" + idLine;
                    var identifyValues = "";
                    var description = this.description;

                    identifyValues = this.identify_values;

                    itensHtml +=
                      '<div class="row levels level-four">';
                    itensHtml +=
                      '<div class="col-sm-3" style="padding-right:0;padding-left:20px;">';
                    itensHtml +=
                      '<span class="description" style="float: left;padding-left: 3px;width: 300px;">' +
                      this.description +
                      "</span>";
                    itensHtml += "</div>";
                    itensHtml +=
                      '<div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">';
                    itensHtml +=
                      '<div class="row" style="padding-right:0;padding-left:0;">';
                    itensHtml +=
                      '<div class="col-sm-1 text-center">&nbsp;</div>';

                    $.each(self.configs.activeDates, function () {
                      itensHtml +=
                        '<div id="' +
                        type +
                        "_level-four_" +
                        this.date_hidden +
                        "_" +
                        agroupOne +
                        agroupTwo +
                        agroupThree +
                        agroupFour +
                        '" class="col-sm-2 text-right showOptions containerLevelFour containerDate sortable" data-date="' +
                        this.date_hidden +
                        '" style="padding-right:0;padding-left:0;" check="N">';
                      itensHtml += "&nbsp;";
                      itensHtml += "</div>";
                    });

                    $.each(self.configs.hiddenDates, function () {
                      itensHtml +=
                        '<div id="' +
                        type +
                        "_level-four_" +
                        this.date_hidden +
                        "_" +
                        agroupOne +
                        agroupTwo +
                        agroupThree +
                        agroupFour +
                        '" class="col-sm-2 text-right showOptions containerLevelFour containerDate sortable" data-date="' +
                        this.date_hidden +
                        '" style="padding-right:0;padding-left:0;display:none;" check="N">';
                      itensHtml += "&nbsp;";
                      itensHtml += "</div>";
                    });

                    itensHtml +=
                      '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';
                    itensHtml += "</div>";
                    itensHtml += "</div>";
                    itensHtml += "</div>";

                    $("#collapse_" + agroupOne + agroupTwo + agroupThree).append(
                      itensHtml
                    );

                    $.each(this.data_values, function () {
                      var htmlApproveShow = "display:none;";
                      var htmlApproveColor = "";
                      var htmlCommentShow = "display:none;";
                      var dataCheck = "N";

                      if (this.vencido && this.value_original > 0) {
                        htmlApproveColor = "color:red;";
                      }

                      if (this.approved && this.value_original > 0) {
                        htmlApproveShow = "display:block;";
                        htmlApproveColor = "color:green;";
                        dataCheck = "S";
                      }

                      if (this.have_comment > 0) {
                        htmlCommentShow = "display:block;";
                      }

                      var future_date = 0;
                      if (this.future_date !== undefined) {
                        future_date = this.future_date;
                      }

                      html = "";
                      html +=
                        '<fields_to_edit level-three-description="' +
                        levelThreeDescription +
                        '" description="' +
                        description +
                        '" data-date="' +
                        this.date +
                        '" data-key-id="' +
                        this.key_id +
                        '" data-identify-value="' +
                        identifyValues +
                        '" data-value="' +
                        this.value +
                        '" data-value-original="' +
                        this.value_original +
                        '" data-coin="' +
                        this.coin +
                        '" data-coin-tax="' +
                        this.coin_tax +
                        '"data-id_field="' +
                        type +
                        "_level-four_" +
                        this.date +
                        "_" +
                        agroupOne +
                        agroupTwo +
                        agroupThree +
                        agroupFour +
                        '" check="' +
                        dataCheck +
                        '" data-internal_table_id="' +
                        this.internal_table_id +
                        '" data-erp_table="' +
                        this.erp_table +
                        "\" data-keys='" +
                        JSON.stringify(this.erp_key) +
                        "' data-tax_keys='" +
                        JSON.stringify(this.tax_key) +
                        "' data-bank='" +
                        JSON.stringify(this.bank) +
                        "' data-payment_method='" +
                        JSON.stringify(this.payment_method) +
                        "' date-diff=\"" +
                        this.date_diff +
                        '" data-erp_table_code="' +
                        this.erp_table_code +
                        '" data-future_date="' +
                        future_date +
                        '" + data-cod_bar="' +
                        this.cod_bar +
                        '" data-vencido="' +
                        this.vencido +
                        '" data-dtoriginal="' +
                        this.data_original +
                        '" data-dataold ="' +
                        this.data_old +
                        '" data-company-token="' +
                        this.company_token +
                        '" data-company-id="' +
                        this.company_id +
                        '" data-id-customer="' +
                        this.id_customer +
                        '" data-payer-id-customer="' +
                        this.payer_id_customer +
                        '" data-payer-company-id="' +
                        this.payer_company_id +
                        '" data-source-table="' +
                        this.source_table +
                        '" data-branch-token="' +
                        this.branch_token +
                        '">';

                      html += "<value_was_altered>";
                      if (this.value != this.value_original) {
                        html += "* ";
                      }
                      html += "</value_was_altered>";

                      if (this.value_original > 0) {
                        html +=
                          '<i class="fa fa-check" style="float: left;' +
                          htmlApproveShow +
                          'cursor: pointer;margin-left: 5px;color:green;"></i>';
                        html +=
                          '<i class="fa fa-comment-o" id="updateCommentIcon" style="float: left;' +
                          htmlCommentShow +
                          'cursor: pointer;margin-left: 5px;" />';
                        html += '<div class="dropdown">';
                        html +=
                          '<i class="fa fa-bars" id="dropdownMenuButton" data-toggle="dropdown" style="float: left;display: none;cursor: pointer;margin-left: 5px;" />';

                        html +=
                          '<div class="dropdown-menu fix-dropdown-position" aria-labelledby="dropdownMenuButton">';
                        if (validateUserAccess(self, identifyValues, "change_date")) {
                          html +=
                            '<a class="dropdown-item" id="updateDate" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Data</a>';
                        }

                        if (this.erp_table == "SE2") {
                          if (
                            validateUserAccess(
                              self,
                              identifyValues,
                              "change_payment_condition"
                            )
                          ) {
                            html +=
                              '<a class="dropdown-item" id="updatePayment" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Forma Pagto.</a>';
                          }
                        }

                        if (this.erp_table == "SE2" || this.erp_table == "SE1") {
                          if (
                            validateUserAccess(
                              self,
                              identifyValues,
                              "register_addition"
                            ) ||
                            validateUserAccess(self, identifyValues, "register_decrease")
                          ) {
                            html +=
                              '<a class="dropdown-item" id="updateIncrease" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Incluir Acresc./Decres.</a>';
                          }
                        }

                        if (
                          this.user_edit_value &&
                          validateUserAccess(self, identifyValues, "change_amount")
                        ) {
                          html +=
                            '<a class="dropdown-item" id="updateValue" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Valor</a>';
                        }

                        // if (
                        //   parseInt(this.coin) != 1 &&
                        //   this.value_original == this.value &&
                        //   (this.erp_table == "SE2" || this.erp_table == "SE1")
                        // ) {
                        //   if (
                        //     validateUserAccess(
                        //       self,
                        //       identifyValues,
                        //       "change_exchange_rate"
                        //     )
                        //   ) {
                        //     html +=
                        //       '<a class="dropdown-item" id="updateCoinTax" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Taxa da Moeda</a>';
                        //   }
                        // }

                        // if (
                        //   validateUserAccess(self, identifyValues, "change_interest_rate")
                        // ) {
                        //   html +=
                        //     '	<a class="dropdown-item" id="updateTax" href="" style="padding: .3rem 1.2rem" data-toggle="modal">Alterar Taxa de Juros</a>';
                        // }

                        if (levelTwoType != "bank") {
                          html +=
                            '<a class="dropdown-item" href="" style="padding: .3rem 1.2rem" id="viewInformation" data-toggle="modal">Visualizar</a>';
                        }

                        if (validateUserAccess(self, identifyValues, "include_comment")) {
                          html +=
                            '<a class="dropdown-item" href="" id="updateComment"  style="padding: .3rem 1.2rem" data-toggle="modal">Comentário</a>';
                        }

                        html += "</div>";
                        html += "</div>";
                      }

                      if (this.payment_method) {
                        if (
                          this.payment_method.type == "2" ||
                          this.payment_method.type == "3"
                        ) {
                          var payment_method = '<div class="data_payment col-sm-4">';
                          var payment_bank = '<div class="data_bank col-sm-6">';
                          payment_method += this.payment_method.description;

                          if (this.bank.A2_BANCO) {
                            payment_bank += " Bco: " + this.bank.A2_BANCO;
                          }

                          if (this.bank.A2_AGENCIA) {
                            payment_bank += " - Ag: " + this.bank.A2_AGENCIA;
                          }
                          if (
                            self.companyGroup.use_digag &&
                            this.bank[self.companyGroup.digag]
                          ) {
                            payment_bank += "-" + this.bank[self.companyGroup.digag];
                          }

                          if (this.bank.A2_NUMCON) {
                            payment_bank += " - Cta: " + this.bank.A2_NUMCON;
                          }

                          if (
                            self.companyGroup.use_digcta &&
                            this.bank[self.companyGroup.digcta]
                          ) {
                            payment_bank += "-" + this.bank[self.companyGroup.digcta];
                          }

                          payment_bank += "</div>";
                          payment_method += "</div>";
                        }
                      }

                      html +=
                        '<span class="valueLevelFour" style="' + htmlApproveColor + '">';
                      html += numeral(this.value).format("0,0.00");
                      html += "</span>";
                      html += "</fields_to_edit>";

                      $(
                        "#" +
                          type +
                          "_level-four_" +
                          this.date +
                          "_" +
                          agroupOne +
                          agroupTwo +
                          agroupThree +
                          agroupFour
                      ).html(html);
                      $(
                        "#" +
                          type +
                          "_level-four_" +
                          this.date +
                          "_" +
                          agroupOne +
                          agroupTwo +
                          agroupThree +
                          agroupFour
                      ).attr("check", dataCheck);
                      $(
                        "#" +
                          type +
                          "_level-four_" +
                          this.date +
                          "_" +
                          agroupOne +
                          agroupTwo +
                          agroupThree +
                          agroupFour
                      )
                        .parent()
                        .append(payment_method);
                      $(
                        "#" +
                          type +
                          "_level-four_" +
                          this.date +
                          "_" +
                          agroupOne +
                          agroupTwo +
                          agroupThree +
                          agroupFour
                      )
                        .parent()
                        .append(payment_bank);
                      $(
                        "#" +
                          type +
                          "_level-four_" +
                          this.date +
                          "_" +
                          agroupOne +
                          agroupTwo +
                          agroupThree +
                          agroupFour
                      )
                        .parent()
                        .find(".data_payment")
                        .not(":first")
                        .remove();
                      $(
                        "#" +
                          type +
                          "_level-four_" +
                          this.date +
                          "_" +
                          agroupOne +
                          agroupTwo +
                          agroupThree +
                          agroupFour
                      )
                        .parent()
                        .find(".data_bank")
                        .not(":first")
                        .remove();
                    });
                  });
                });

                $("#collapse_" + agroupOne + agroupTwo).append(
                  '<div id="' + this.id + '" class="container_simulation"></div>'
                );

                $.each(this.data_simulation, function () {
                  var fieldId = "";
                  var idSimulation = this.id;

                  html = "";
                  html +=
                    '<div id="simulation_' +
                    agroupOne +
                    agroupTwo +
                    "_" +
                    this.id +
                    '" data-type="' +
                    dataType +
                    '" class="row simulation level_three">';
                  html +=
                    '<input type="hidden" class="simulation_id" id="simulation_id" name="simulation_id" value="' +
                    this.id +
                    '">';
                  html +=
                    '<div class="col-sm-3" style="padding-right:0;padding-left:20px;">';
                  html +=
                    '<input type="text" class="form-control form-control-sm m-input m-input--solid description" id="' +
                    this.id +
                    '_simulation_description" name="' +
                    this.id +
                    '_simulation_description" value="' +
                    this.description +
                    '"';

                  if (!self.user_include_simulation) {
                    html += " disabled";
                  }

                  html += " />";

                  html += "</div>";
                  html +=
                    '<div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">';
                  html += '<div class="row" style="padding-right:0;padding-left:0;">';
                  html +=
                    '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;</div>';

                  $.each(self.configs.activeDates, function () {
                    fieldId =
                      type +
                      "_level-three_" +
                      this.date_hidden +
                      "_" +
                      agroupOne +
                      agroupTwo +
                      "_" +
                      idSimulation +
                      "-simulation";

                    html +=
                      '<div class="col-sm-2 text-center containerDate" data-date="' + this.date_hidden +'">';
                    html +=
                      '<input type="text" class="form-control form-control-sm m-input m-input--solid text-right containerLevelThreeSimulation value" id="' +
                      fieldId +
                      '" data-date="' +
                      this.date_hidden +
                      '" name="' +
                      fieldId +
                      '" value="' +
                      numeral(0).format("0,0.00") +
                      '"';

                    if (!self.user_include_simulation) {
                      html += " disabled";
                    }

                    html += " />";

                    html += "</div>";
                  });

                  $.each(self.configs.hiddenDates, function () {
                    fieldId =
                      type +
                      "_level-three_" +
                      this.date_hidden +
                      "_" +
                      agroupOne +
                      agroupTwo +
                      "_" +
                      idSimulation +
                      "-simulation";

                    html +=
                      '<div class="col-sm-2 text-center containerDate" style="display:none;" data-date="' + this.date_hidden +'">';
                    html +=
                      '<input type="text" class="form-control form-control-sm m-input m-input--solid text-right containerLevelThreeSimulation value" id="' +
                      fieldId +
                      '" data-date="' +
                      this.date_hidden +
                      '" name="' +
                      fieldId +
                      '" value="' +
                      numeral(0).format("0,0.00") +
                      '"';

                    if (!self.user_include_simulation) {
                      html += " disabled";
                    }

                    html += " />";
                    html += "</div>";
                  });

                  html +=
                    '<div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">';

                  if (self.user_include_simulation) {
                    html +=
                      '<i class="la la-check-circle-o" rowId="' +
                      this.id +
                      '" style="font-size: 22px;padding-top: 2px; margin: 0px 4px; cursor:pointer;"></i>';
                    html +=
                      '<i class="la la-trash-o simulation" rowId="' +
                      this.id +
                      '" style="font-size: 22px;padding-top: 2px;cursor:pointer;"></i>';
                  }

                  html += "</div>";
                  html += "</div>";
                  html += "</div>";
                  html += "</div>";

                  $("#fluxo > .m-portlet__body > #" + idForSimulation).append(html);
                  $(
                    "#collapse_" + agroupOne + agroupTwo + " > #" + idForSimulation
                  ).append(html);

                  $.each(this.data_values, function () {
                    fieldId =
                      type +
                      "_level-three_" +
                      this.date +
                      "_" +
                      agroupOne +
                      agroupTwo +
                      "_" +
                      idSimulation +
                      "-simulation";

                    $("#" + fieldId).val(numeral(this.value).format("0,0.00"));
                  });

                  $(".containerLevelThreeSimulation").mask("00.000.000.000,00", {
                    reverse: true,
                  });
                });
              });
            });
          }
        });

        $('i[data-toggle="dropdown"]').on('click', fixDropdownPosition);

        if (report_type == "S") {
          $(".collapse .show").each(function () {
            this.className = "collapse";
          });
        }
        recalcule(self);
        showLevels(report_type, nivel);
        dragMethods(self);
      } else {
        showNotification("Atenção", result.message, result.type);
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/cashflow';
</style>