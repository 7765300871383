export default class InnCashCredentialsConfiguration {
	constructor(
		public url: string,
		public email: string,
		public password: string,
	) { }

	static make(data: any): InnCashCredentialsConfiguration {
		return new InnCashCredentialsConfiguration(
            data.url,
            data.email,
            data.password,
		);
	}
}
