import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-7",attrs:{"fluid":""}},[_c(VForm,{ref:"IntegratorDatabaseConnectionConfigForm"},[_c(VRow,[_c(VCol,[_vm._v(" Configurações de Banco do Cliente ")])],1),_c(VDivider),_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"label":"Nome do Sistema","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.databaseConnectionData.customerSystem),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerSystem", $$v)},expression:"databaseConnectionData.customerSystem"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"label":"Banco","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(32) ]},model:{value:(_vm.databaseConnectionData.customerClient),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerClient", $$v)},expression:"databaseConnectionData.customerClient"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Usuário","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.databaseConnectionData.customerUser),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerUser", $$v)},expression:"databaseConnectionData.customerUser"}})],1),_c(VCol,[_c(VTextField,{staticClass:"number-input-without-arrows",attrs:{"label":"Porta","filled":"","height":"56px","type":"number","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.isNumber(),
            _vm.inputRules.sizeEqualThen(4) ]},model:{value:(_vm.databaseConnectionData.customerPort),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerPort", $$v)},expression:"databaseConnectionData.customerPort"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Host","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.databaseConnectionData.customerHost),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerHost", $$v)},expression:"databaseConnectionData.customerHost"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Senha","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.databaseConnectionData.customerPassword),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerPassword", $$v)},expression:"databaseConnectionData.customerPassword"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Banco de Dados","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.databaseConnectionData.customerDatabase),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerDatabase", $$v)},expression:"databaseConnectionData.customerDatabase"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"String de Conexão","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.lowerOrEqualThen(128) ]},model:{value:(_vm.databaseConnectionData.customerConnectString),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "customerConnectString", $$v)},expression:"databaseConnectionData.customerConnectString"}})],1)],1),_c(VRow,[_c(VCol,[_vm._v(" Configurações de tabelas do Inncash ")])],1),_c(VDivider),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Banco do Inncash","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(32) ]},model:{value:(_vm.databaseConnectionData.inncashDatabase),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "inncashDatabase", $$v)},expression:"databaseConnectionData.inncashDatabase"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Nome da Tabela de Controle","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(32) ]},model:{value:(_vm.databaseConnectionData.inncashControlTable),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "inncashControlTable", $$v)},expression:"databaseConnectionData.inncashControlTable"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Nome da Tabela de Jobs","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(32) ]},model:{value:(_vm.databaseConnectionData.inncashTableJob),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "inncashTableJob", $$v)},expression:"databaseConnectionData.inncashTableJob"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Nome da Tabela de Controle de Atualização","filled":"","height":"56px","loading":_vm.loading,"rules":[
            _vm.inputRules.required(),
            _vm.inputRules.lowerOrEqualThen(32) ]},model:{value:(_vm.databaseConnectionData.inncashUpdateControlTable),callback:function ($$v) {_vm.$set(_vm.databaseConnectionData, "inncashUpdateControlTable", $$v)},expression:"databaseConnectionData.inncashUpdateControlTable"}})],1)],1)],1),_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"button-width",attrs:{"color":"success","large":"","loading":_vm.loading},on:{"click":_vm.handleClickSaveDatabaseConnectionConfig}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }